import React from "react";
import Lottie from "lottie-react";
import loader from "../assets/loader.json";

export default function Intro(props) {
  return (
    <div id="intro">
      <Lottie animationData={loader.light} />
    </div>
  );
}
