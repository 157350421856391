import moment from "moment";
import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Button from "../components/styled/button";
import Checkbox from "../components/styled/checkbox";
import Input from "../components/styled/input";
import TextArea from "../components/styled/textArea";
import { Select } from "../components/styled/select";
import clone from "clone";
import { Store } from "../stores/stores.jsx";
import Loader from "../components/global/loader.jsx";
import ConfigSelector from "../components/configSelector.jsx";
import {
  GET_THEMES_BY_CONFIG_ID,
  POST_THEME_BROWSER_ENTRY,
  GET_THEME_BROWSER_ENTRIES,
  POST_THEME_BROWSER_ENTRY_UPDATE,
  POST_THEME_BROWSER_ENTRY_ARCHIVE
} from "../graphql/queries.js";

const truncateString = (str, startLength = 4, endLength = 4) => {
  if (str.length > 30) {
    const startStr = str.substring(0, startLength);
    const endStr = str.substring(str.length - endLength);
    return `${startStr}...${endStr}`;
  }
  return str;
};

export default function ThemeBrowser() {
  const addNotification = Store.useState(s => s.addNotification);
  const [showForm, setShowForm] = useState(false);
  const [themeToEdit, setThemeToEdit] = useState(null);

  const { data: themesList, refetch } = useQuery(GET_THEME_BROWSER_ENTRIES);
  const [archiveTheme] = useMutation(POST_THEME_BROWSER_ENTRY_ARCHIVE);

  const showEditForm = theme => {
    setShowForm(true);
    setThemeToEdit(theme);
  };
  const handleArchive = async theme => {
    if (window.confirm("Are you sure you want to archive this theme ? It will no longer accessible")) {
      await archiveTheme({
        variables: { themeEntryRef: theme._id }
      });
      refetch();
    }
  };

  const editModeBcg = {
    filter: `blur(${showForm ? 5 : 0}px)`,
    pointerEvents: showForm && "none"
  };

  return (
    <div id="themeBrowser">
      <div className="header">
        <h1>Theme Browser</h1>
        <Button onClick={() => setShowForm(true)} style={editModeBcg}>
          Add new entry
        </Button>
      </div>
      <div className="themeList" style={editModeBcg}>
        {themesList &&
          themesList.themeBrowserEntries.map((theme, index) => (
            <div key={index} className={`theme ${theme.published ? "published" : "private"}`}>
              <h2>
                {theme.title} {theme.published ? "" : " - PRIVATE"}{" "}
              </h2>
              <p>
                Description: <strong>{theme.description}</strong>
              </p>
              <p>
                Credits: <strong>{theme.credits}</strong>
              </p>
              <p>Date: {moment(theme.createdAt).format("ll")}</p>
              <p>
                Tags:
                {theme.tags.map((element, index) => (
                  <Button key={index} disabled type="button" style={{ margin: "6px" }}>
                    {truncateString(element, 10)}
                  </Button>
                ))}
              </p>
              <div className="bannerBox">
                {theme.bannerImages.map(i => (
                  <div key={i.uri}>
                    <img key={i} src={i.uri} alt="banner" />
                    <small>{truncateString(i.name)}</small>
                  </div>
                ))}
              </div>
              <div className="buttons">
                <Button
                  type="button"
                  variant="danger"
                  style={{ marginRight: "12px" }}
                  onClick={() => handleArchive(theme)}>
                  Archive
                </Button>
                <Button type="button" onClick={() => showEditForm(theme)}>
                  Edit
                </Button>
              </div>
            </div>
          ))}
      </div>
      {showForm && (
        <div className="formEntries">
          <ThemeBrowserForm
            themeToEdit={themeToEdit}
            addNotification={addNotification}
            refetchThemes={refetch}
            onClose={() => {
              setShowForm(false);
              setThemeToEdit(null);
            }}
          />
        </div>
      )}
    </div>
  );
}

const ThemeBrowserForm = ({ themeToEdit, addNotification, onClose, refetchThemes }) => {
  const [theme, setTheme] = useState({
    bannerImages: [],
    tags: themeToEdit?.tags || [],
    title: themeToEdit?.title || "",
    credits: themeToEdit?.credits || "",
    published: themeToEdit?.published || false,
    themeEntryRef: themeToEdit?._id || null,
    createdAt: moment.unix(themeToEdit?.createdAt / 1000).format("YYYY-MM-DD") || "",
    description: themeToEdit?.description || ""
  });

  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);

  const [addTheme, { loading: addThemeLoading }] = useMutation(POST_THEME_BROWSER_ENTRY);
  const [getThemes, { data: themesData, loading }] = useLazyQuery(GET_THEMES_BY_CONFIG_ID, {
    fetchPolicy: "cache-and-network"
  });
  const [editTheme, { loading: editThemeLoading }] = useMutation(POST_THEME_BROWSER_ENTRY_UPDATE);

  const [tagInput, setTagInput] = useState("");
  const updateTheme = updatedFields => setTheme({ ...theme, ...updatedFields });
  const handleSubmit = async e => {
    e.preventDefault();
    if (!requiredFieldsFilled) return;

    const {
      title,
      description,
      createdAt,
      credits,
      tags,
      bannerImages,
      themeRef,
      configRef,
      themeEntryRef,
      published
    } = theme;
    if (themeToEdit) {
      await editTheme({
        variables: { themeEntryRef, themeRef, createdAt, title, tags, credits, bannerImages, description, published }
      });
      addNotification({ ok: 1, message: "Theme updated" });
    } else {
      await addTheme({
        variables: { configRef, themeRef, title, tags, credits, bannerImages, description, published }
      });
      addNotification({ ok: 1, message: "Theme added" });
    }
    await refetchThemes();
    setTagInput(() => "");
    setTheme(prevState => ({
      ...prevState,
      tags: [],
      title: "",
      credits: "",
      createdAt: "",
      description: "",
      bannerImages: []
    }));
    onClose();
  };

  useEffect(() => {
    if (themeToEdit && theme.themeEntryRef && theme.title && theme.description) {
      setRequiredFieldsFilled(true);
      getThemes({ variables: { configRef: themeToEdit.configRef } });
    } else if (theme.configRef && theme.themeRef && theme.title && theme.description) setRequiredFieldsFilled(true);
    else setRequiredFieldsFilled(false);
  }, [theme, themeToEdit, getThemes]);

  const handleAddTag = e => {
    const tagToAdd = e.target.value;
    if (e.key === "Enter" && tagToAdd.trim() !== "") {
      if (!theme.tags) theme.tags = [];
      setTheme(prevState => ({
        ...prevState,
        tags: [...prevState.tags, tagToAdd.trim()]
      }));
      setTagInput(() => "");
    }
  };

  const handleDeleteTag = tagToDelete => {
    // const tagIndex = theme.tags.indexOf(tagToDelete);
    // theme.tags.splice(tagIndex, 1);
    setTheme(prevState => ({
      ...prevState,
      tags: clone(theme.tags.filter(t => t !== tagToDelete))
    }));
    console.log(tagToDelete);
  };

  const handleSelectConfig = async config => {
    if (config) {
      await getThemes({ variables: { configRef: config._id } });
      setTheme(prevState => ({
        ...prevState,
        themeRef: null,
        configRef: config._id
      }));
    } else {
      setTheme(prevState => ({
        ...prevState,
        themeRef: null,
        configRef: null
      }));
    }
  };

  const handleSelectTheme = theme => {
    setTheme(prevState => ({
      ...prevState,
      themeRef: theme.value
    }));
  };

  const bannerImgUpload = event => {
    setTheme(prevState => ({
      ...prevState,
      bannerImages: Array.from(event.target.files)
    }));
  };
  return (
    <form onSubmit={handleSubmit}>
      {!themeToEdit && (
        <div className="formGroup">
          <label>Select config</label>
          <ConfigSelector onChangeConfig={handleSelectConfig} />
        </div>
      )}
      {loading && <Loader />}
      {themesData && (
        <div className="formGroup">
          <label>Select theme</label>
          <Select
            variant="overZone"
            defaultValue={{
              label:
                (themeToEdit && themesData.themesByConfigId.find(t => t._id === themeToEdit.themeRef)?.title) ??
                "All themes",
              value: themeToEdit?.themeRef
            }}
            options={[
              {
                label: "Themes",
                options: themesData.themesByConfigId.map(t => ({ label: t.title, value: t._id }))
              }
            ]}
            onChange={handleSelectTheme}
          />
        </div>
      )}
      <div className="formGroup">
        <label>Title</label>
        <Input
          type="text"
          name="title"
          variant="overZone"
          className="noBorderOnDisabled"
          value={theme.title}
          onChange={e => updateTheme({ title: e.target.value })}
        />
      </div>
      <div className="formGroup">
        <label>Description</label>
        <TextArea
          name="description"
          variant="overZone"
          placeholder="Add details"
          value={theme.description}
          onChange={e => updateTheme({ description: e.target.value })}
        />
      </div>
      <div className="formGroup">
        <label>Credits</label>
        <Input
          type="text"
          name="credits"
          variant="overZone"
          className="noBorderOnDisabled"
          value={theme.credits}
          onChange={e => updateTheme({ credits: e.target.value })}
          placeholder="Shout out to my homies"
        />
      </div>
      <div className="formGroup">
        <label>Tags</label>
        <Input
          type="text"
          name="title"
          variant="overZone"
          placeholder="Add tags"
          className="noBorderOnDisabled"
          value={tagInput}
          onKeyDown={handleAddTag}
          onChange={e => setTagInput(e.target.value)}
          onKeyPress={e => {
            e.key === "Enter" && e.preventDefault();
          }}
        />
        <div className="tagsList">
          {theme.tags?.length > 0 &&
            theme.tags.map((element, index) => (
              <Button key={element + index} variant="danger" type="button" onClick={() => handleDeleteTag(element)}>
                {truncateString(element, 10)}
              </Button>
            ))}
        </div>
      </div>
      {themeToEdit && (
        <div className="formGroup">
          <label>Date</label>
          <Input
            type="date"
            name="createdAt"
            variant="overZone"
            className="noBorderOnDisabled"
            value={theme.createdAt}
            onChange={e => updateTheme({ createdAt: e.target.value })}
          />
        </div>
      )}
      <div className="formGroup">
        <label>Banner image</label>
        <input id="fileInput" type="file" accept=".jpg, .jpeg, .png" multiple onChange={e => bannerImgUpload(e)} />
      </div>

      <Checkbox
        type="checkbox"
        onChange={e => setTheme({ ...theme, published: e.target.checked })}
        label="Published"
        checked={theme.published}
      />
      <div className="buttons">
        {addThemeLoading || editThemeLoading ? null : (
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        )}
        <Button type="submit" disabled={!requiredFieldsFilled || addThemeLoading || editThemeLoading}>
          {addThemeLoading || editThemeLoading ? <Loader /> : "Submit"}
        </Button>
      </div>
    </form>
  );
};
