import React, { useState } from "react";
import URI from "urijs";
import Button from "../components/styled/button";
import Input from "../components/styled/input";
import Zone from "../components/styled/zone";
import Pagination from "../components/global/pagination";
import moment from "moment";
import { Store } from "../stores/stores";
import {
  GET_INVOICES,
  POST_INVOICE,
  POST_INVOICE_GENERATE,
  POST_INVOICE_MARK_AS_PAID,
  POST_INVOICE_UPLOAD,
  POST_INVOICE_UPLOAD_PENNYLANE
} from "../graphql/queries";
import Loader from "../components/global/loader";
import { useLocation, useHistory } from "react-router";
import ConfigSelector from "../components/configSelector";
import { useMutation, useQuery } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";

export default function Invoices() {
  const location = useLocation();
  const history = useHistory();
  const currentUri = new URI(location.pathname + location.search);
  const query = currentUri.search(true);
  const [showCreate, setShowCreate] = useState(false);
  const addNotification = Store.useState(s => s.addNotification);
  const [newInvoices, setNewInvoices] = useState([]);

  const filters = {
    page: query.page && parseInt(query.page),
    perPage: query.perPage && parseInt(query.perPage),
    sortOrder: query.sortOrder && parseInt(query.sortOrder),
    sortBy: query.sortOrder,
    configRef: query.configRef
  };

  const { loading, data } = useQuery(GET_INVOICES, { variables: filters });

  const invoices = data?.invoices?.invoices;
  const pagination = data?.invoices?.pagination;

  const onClickCreateInvoice = e => {
    e.preventDefault();
    if (!showCreate && !filters.configRef) return addNotification({ ok: 0, message: "Please select a config" });
    setShowCreate(!showCreate);
  };

  const onInvoiceCreated = invoice => {
    setShowCreate(false);
    setNewInvoices([invoice, ...newInvoices]);
  };

  const handleChangeConfig = e => {
    const configRef = e ? e._id : null;
    const uri = currentUri.clone();

    if (configRef) uri.setSearch("configRef", configRef);
    else uri.removeSearch("configRef");
    uri.removeSearch("page");
    history.push(uri.toString());
  };

  return (
    <div id="invoices">
      <section className="header">
        <div className="left">
          <h1>Invoices</h1>
          <ConfigSelector onChangeConfig={handleChangeConfig} />
        </div>
        <div className="right">
          <Button variant={showCreate ? "secondary" : "primary"} onClick={onClickCreateInvoice}>
            {showCreate ? "Cancel" : "Create New Invoice"}
          </Button>
        </div>
      </section>
      {showCreate && <CreateInvoice onInvoiceCreated={onInvoiceCreated} configRef={filters.configRef} />}
      <section className="tableContainer">
        {loading ? null : <Pagination pagination={pagination} />}
        <div className="invoicesTable">
          {newInvoices.map((invoice, i) => (
            <InvoiceEntry key={invoice.id} invoice={invoice} />
          ))}
          {invoices && !loading ? (
            invoices.map((invoice, i) => <InvoiceEntry key={`${i}-${invoice.id}`} invoice={invoice} />)
          ) : (
            <Loader />
          )}
        </div>
        {loading ? null : <Pagination pagination={pagination} />}
      </section>
    </div>
  );
}

const CreateInvoice = ({ onInvoiceCreated, configRef }) => {
  const addNotification = Store.useState(s => s.addNotification);
  const [items, setItems] = useState([{ title: "", price: "" }]);
  const [createInvoice, { loading }] = useMutation(POST_INVOICE);
  const [showPaymentDate, setShowPaymentDate] = useState(false);

  const onSubmitInvoice = async e => {
    e.preventDefault();
    try {
      if (!configRef) throw new Error("Please select a config");
      const { data } = await createInvoice({
        variables: {
          configRef,
          method: e.target.method.value,
          isPaid: e.target.isPaid.checked,
          date: e.target.date?.value,
          title: e.target.title.value,
          items
        }
      });
      onInvoiceCreated(data.invoiceCreate);
    } catch (error) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };
  const onChangeItem = (index, field, value) => {
    items[index][field] = value;
    setItems([...items]);
  };
  const deleteItem = index => {
    if (items.length === 1) return addNotification({ ok: 0, message: "Invoice must have at least one item" });

    items.splice(index, 1);
    setItems([...items]);
  };
  const addItem = () => {
    items.push({ title: "", price: "" });
    setItems([...items]);
  };

  return (
    <Zone className="createInvoice">
      <form onSubmit={onSubmitInvoice}>
        <div className="content">
          <div className="invoiceItems">
            <Input
              variant="overZone"
              required
              label="Invoice Title"
              type="text"
              name="title"
              placeholder="The invoice title"
            />
            <Input
              variant="overZone"
              label="Payment Method"
              type="text"
              name="method"
              placeholder="Payment method description"
            />
          </div>
          <div className="invoiceItems">
            {items.map((i, index) => (
              <div key={index} className="invoiceItem">
                <Input
                  placeholder="The item description"
                  type="text"
                  variant="overZone"
                  required
                  label="Description"
                  onChange={e => onChangeItem(index, "title", e.target.value)}
                  value={i.title}
                />
                <Input
                  placeholder="The item amount before taxes"
                  type="number"
                  variant="overZone"
                  required
                  label="Amount before taxes - HT"
                  onChange={e => onChangeItem(index, "price", parseInt(e.target.value))}
                  value={i.price}
                />
                <Button
                  variant="noStyle"
                  className="cg-icon-close"
                  disabled={loading}
                  type="button"
                  onClick={() => deleteItem(index)}>
                  Delete
                </Button>
              </div>
            ))}
            <span>
              <Button onClick={addItem} type="button" variant="secondaryOverZone">
                Add Item
              </Button>
            </span>
          </div>
        </div>

        <div className="invoiceItems">
          <label className="inline">
            <input name="isPaid" type="checkbox" onChange={e => setShowPaymentDate(e.target.checked)} />
            Invoice is already paid
          </label>
          {showPaymentDate && (
            <>
              <label>Payment Date</label>
              <input type="date" name="date" />
            </>
          )}
          <div>
            <Button type="submit">{loading ? <Loader /> : "Create Invoice"}</Button>
          </div>
        </div>
      </form>
    </Zone>
  );
};

const InvoiceEntry = ({ invoice: defaultInvoice }) => {
  const [invoice, setInvoice] = useState(cloneDeep(defaultInvoice));
  let className = invoice.paid.status ? "paid" : "unpaid";
  if (invoice.paid.processing) className = "processing";
  const [invoiceUploadPDF, { loading: loadingUploadPDF }] = useMutation(POST_INVOICE_UPLOAD);
  const [invoiceUploadPennylane, { loading: loadingPennylane }] = useMutation(POST_INVOICE_UPLOAD_PENNYLANE);
  const [invoiceGeneratePDF, { loading: loadingGeneratePDF }] = useMutation(POST_INVOICE_GENERATE);
  const [invoiceMarkAsPaid, { loading: loadingMarkAsPaid }] = useMutation(POST_INVOICE_MARK_AS_PAID);
  const addNotification = Store.useState(s => s.addNotification);
  const [markAsPaid, setMarkAsPaid] = useState(false);

  const onChangeUploadPdf = async e => {
    e.preventDefault();
    try {
      if (!e.target.validity.valid || !e.target.files.length) throw new Error("Invalid file");
      const { data } = await invoiceUploadPDF({ variables: { invoiceRef: invoice._id, file: e.target.files[0] } });
      setInvoice({ ...invoice, pdf: data.invoiceUpload.pdf });
      addNotification({ ok: 1, message: "Invoice PDF uploaded" });
      e.target.value = null;
    } catch (error) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  const onUploadToPennylane = async e => {
    e.preventDefault();
    try {
      const { data } = await invoiceUploadPennylane({ variables: { invoiceRef: invoice._id } });
      addNotification({ ok: 1, message: data.invoiceUploadPennylane });
    } catch (error) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };
  const onClickGeneratePDF = async e => {
    e.preventDefault();
    try {
      const { data } = await invoiceGeneratePDF({ variables: { invoiceRef: invoice._id } });
      setInvoice({ ...invoice, pdf: data.invoiceGeneratePdf.pdf });
      addNotification({ ok: 1, message: "Invoice PDF generated" });
    } catch (error) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  const onSubmitMarkAsPaid = async e => {
    e.preventDefault();
    try {
      const { data } = await invoiceMarkAsPaid({
        variables: { invoiceRef: invoice._id, paid: { status: true, method: e.target.paymentMethod.value } }
      });
      setInvoice(data.invoiceMarkAsPaid);
      addNotification({ ok: 1, message: "Invoice marked as paid" });
    } catch (error) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  return (
    <div className={`entry ${className}`}>
      <div>#{invoice.id}</div>
      <div>
        {moment.unix(invoice.created / 1000).format("MMM DD, YYYY ")}
        <p className="fromNow">({moment.unix(invoice.created / 1000).fromNow()})</p>
      </div>
      <div className="">{invoice.shopName}</div>
      <div className="">{invoice.title}</div>
      <div className="paidStatus">
        <div className="">
          {invoice.paid.status ? "Paid" : "Unpaid"}
          {invoice.paid.method ? <span className="paymentMethod"> ({invoice.paid.method})</span> : ""}
        </div>
        <div className="">
          {invoice.total?.currencySymbol}
          {invoice.total?.currency}
        </div>
        {!invoice.paid.status && (
          <div className="markAsPaid">
            <Button variant={markAsPaid ? "danger" : "secondary"} onClick={() => setMarkAsPaid(!markAsPaid)}>
              {markAsPaid ? "Cancel" : "Mark as Paid"}
            </Button>
            {markAsPaid && (
              <form onSubmit={onSubmitMarkAsPaid}>
                <div className="formGroup">
                  <input required name="paymentMethod" placeholder="Payment method" disabled={loadingMarkAsPaid} />
                  {
                    <Button type="submit" disabled={loadingMarkAsPaid}>
                      {loadingMarkAsPaid ? <Loader /> : "Submit"}
                    </Button>
                  }
                </div>
              </form>
            )}
          </div>
        )}
      </div>
      <div className="pdfActions">
        <div className="downloadPdf">
          {invoice.pdf ? (
            <a target="_blank" href={invoice.pdf} rel="noreferrer">
              Download PDF
            </a>
          ) : null}
        </div>
        <div className="uploadPdf">
          <label htmlFor={`pdf - ${invoice._id}`}>{loadingUploadPDF ? <Loader /> : "Upload PDF"}</label>
          <input id={`pdf - ${invoice._id}`} type="file" disabled={loadingUploadPDF} onChange={onChangeUploadPdf} />
        </div>
        <div className="generatePdf">
          <Button variant="secondary" onClick={onClickGeneratePDF} disabled={loadingGeneratePDF}>
            {loadingGeneratePDF ? <Loader /> : "Generate PDF"}
          </Button>
          {invoice.pdf ? (
            <Button disabled={loadingPennylane} variant="secondary" type="button" onClick={onUploadToPennylane}>
              To PennyLane
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
