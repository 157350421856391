import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Loader from "../components/global/loader";
import { MainLogoLetters, NoTextComponent } from "../components/global/icons";
import { POST_LOGIN } from "../graphql/queries";
import { Store } from "../stores/stores";
import { Buffer } from "buffer";
import Button from "../components/styled/button";
import styled from "styled-components";

export default function Login() {
  const [login, { loading: loginLoading }] = useMutation(POST_LOGIN);
  const [error, setError] = useState(null);
  const onSubmit = async e => {
    e.preventDefault();
    try {
      const results = await login({
        variables: {
          email: Buffer.from(e.target.email.value).toString("base64"),
          password: Buffer.from(e.target.password.value).toString("base64")
        }
      });
      const succeeded = !!results?.data?.login;
      if (succeeded) {
        localStorage.setItem("jwt", results.data.login.jwt);
        // TODO: reloadConfig();
      } else setError("Login error");

      Store.update(s => {
        s.session = results?.data?.login;
      });
    } catch (e) {
      setError(e.toString());
      Store.update(s => {
        s.session = undefined;
      });
    }
  };
  return (
    <div id="loginWrapper">
      <div id="login">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <NoTextComponent />
        </div>
        <form id="loginForm" onSubmit={onSubmit}>
          <div className="formGroup">
            <label>Enter your email:</label>
            <input type="email" name="email" placeholder="Your email address" required />
          </div>
          <div className="formGroup">
            <label>Enter your password:</label>
            <input type="password" name="password" placeholder="Your password" required />

            <br />
          </div>
          <Button type="submit" variant="primary" label="Login">
            {loginLoading ? <Loader /> : "Login"}
          </Button>
        </form>
        {error ? <p className="danger">{error}</p> : null}
      </div>
    </div>
  );
}
