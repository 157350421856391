import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import Loader from "../components/global/loader";
import { GET_TEMPLATES, POST_CREATE_TEMPLATE } from "../graphql/queries";
import clone from "clone";
import ButtonComponent from "../components/styled/button";

export default function Dictionaries() {
  const history = useHistory();
  const { data, loading } = useQuery(GET_TEMPLATES, { fetchPolicy: "cache-and-network" });
  const [createTemplate] = useMutation(POST_CREATE_TEMPLATE);

  const handleCreateTemplate = async e => {
    const { data } = await createTemplate({ variables: { title: "New template" } });
    history.push(`/template/${data.templateCreate._id}`);
  };

  if (loading || !data) return <Loader />;

  const templates = clone(data.templates.templates);

  const sort = (a, b) => {
    return a.title < b.title ? -1 : 1;
  };

  const cgTemplates = templates.filter(t => t.title.includes("CG to"));
  const shopTemplates = templates.filter(t => t.title.includes("Shop to"));
  const others = templates.filter(t => !t.title.includes("Shop to") && !t.title.includes("CG to"));

  return (
    <div id="templates">
      <section className="header">
        <h1>Templates</h1>
        <ButtonComponent variant="primary" type="button" onClick={handleCreateTemplate}>
          Create template
        </ButtonComponent>
      </section>
      <div className="content">
        <section>
          <h2>Shop templates</h2>
          <hr />

          {shopTemplates.sort(sort).map(t => (
            <div className="template" key={t._id}>
              <Link to={`/template/${t._id}`}>{t.title}</Link>
              <p>{t.type}</p>
              <p>v{t.version}</p>
              <p>{t.createdBy?.name}</p>
            </div>
          ))}
        </section>
        <section>
          <h2>CG templates</h2>
          <hr />

          {cgTemplates.sort(sort).map(t => (
            <div className="template" key={t._id}>
              <Link to={`/template/${t._id}`}>{t.title}</Link>
              <p>{t.type}</p>
              <p>v{t.version}</p>
              <p>{t.createdBy?.name}</p>
            </div>
          ))}
        </section>
        <section>
          <h2>Others</h2>
          <hr />
          {others.sort(sort).map(t => (
            <div className="template" key={t._id}>
              <Link to={`/template/${t._id}`}>{t.title}</Link>
              <p>{t.type}</p>
              <p>v{t.version}</p>
              <p>{t.createdBy?.name}</p>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}
