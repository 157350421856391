import React from "react";
import styled from "styled-components";
import colors from "@common-ground-io/common-assets/assets/colors.json";
import { applyOpacity, colorsAsRgbArray, colorsAsRgbString } from "@common-ground-io/colors";

const layout = {
  light: {
    primary: {
      borderColor: colorsAsRgbString.primary,
      borderColorHover: colorsAsRgbString.primary,
      borderColorActive: colorsAsRgbString.primary,
      borderColorDisabled: applyOpacity(colorsAsRgbArray.primary, 0.6),
      // background
      bgColor: colorsAsRgbString.primary,
      bgColorHover: applyOpacity(colorsAsRgbArray.primary, 0.6),
      bgColorActive: applyOpacity(colorsAsRgbArray.primary, 0.1),
      bgColorDisabled: applyOpacity(colorsAsRgbArray.primary, 0.1),
      // font
      fontColor: colorsAsRgbString.white,
      fontColorActive: colorsAsRgbString.primary,
      fontColorHover: colorsAsRgbString.white,
      fontColorDisabled: applyOpacity(colorsAsRgbArray.primary, 0.4)
    },
    secondary: {
      // border
      borderColor: applyOpacity(colorsAsRgbArray.greyLight, 0.2),
      borderColorHover: colorsAsRgbString.greyLight,
      borderColorActive: colorsAsRgbString.greyDark,
      borderColorDisabled: applyOpacity(colorsAsRgbArray.grey, 0.1),
      // background
      bgColor: applyOpacity(colorsAsRgbArray.greyDark, 0.1),
      bgColorHover: applyOpacity(colorsAsRgbArray.greyDark, 0.4),
      bgColorActive: colorsAsRgbString.grey,
      bgColorDisabled: applyOpacity(colorsAsRgbArray.grey, 0.1),
      // font
      fontColor: colorsAsRgbString.grey,
      fontColorHover: colorsAsRgbString.greyLightest,
      fontColorActive: colorsAsRgbString.white,
      fontColorDisabled: applyOpacity(colorsAsRgbArray.grey, 0.4)
    },
    info: {
      bcgColor: colors.blueLight,
      fontColor: colors.blueDark,
      borderColor: "transparent",
      borderColorHover: colors.blueDark,
      fontColorHover: colors.blueDark,
      bcgColorActive: colors.blueDark,
      borderColorActive: "transparent",
      fontColorActive: colors.blueLight
    },
    success: {
      bcgColor: colors.greenLight,
      fontColor: colors.greenDark,
      borderColor: "transparent",
      borderColorHover: colors.greenDark,
      fontColorHover: colors.greenDark,
      bcgColorActive: colors.greenDark,
      borderColorActive: "transparent",
      fontColorActive: colors.greenLight
    },
    danger: {
      borderColor: colorsAsRgbString.alert,
      borderColorHover: colorsAsRgbString.alert,
      borderColorActive: colorsAsRgbString.alert,
      borderColorDisabled: applyOpacity(colorsAsRgbArray.primary, 0.6),
      // background
      bgColor: applyOpacity(colorsAsRgbArray.alert, 0.2),
      bgColorHover: applyOpacity(colorsAsRgbArray.alert, 0.6),
      bgColorActive: applyOpacity(colorsAsRgbArray.alert, 0.1),
      bgColorDisabled: applyOpacity(colorsAsRgbArray.alert, 0.1),
      // font
      fontColor: colorsAsRgbString.alert,
      fontColorActive: colorsAsRgbString.alert,
      fontColorHover: colorsAsRgbString.alert,
      fontColorDisabled: applyOpacity(colorsAsRgbArray.primary, 0.4)
    },
    noStyle: {
      bcgColor: "transparent",
      borderColor: "transparent",
      fontColor: colors.greyDark,
      borderColorHover: "transparent",
      fontColorHover: colors.greyDarker,
      bcgColorActive: "transparent",
      borderColorActive: "transparent",
      fontColorActive: colors.greyDarker
    }
  }
};

const StyledButton = styled.button`
  height: auto;
  padding: 12px 13px;
  border: 1px solid ${props => path(layout, props).borderColor};
  border-radius: 10px;
  cursor: pointer;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  font-style: normal;
  line-height: 1;
  text-align: "center";
  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(layout, props).bgColor};
  color: ${props => path(layout, props).fontColor};
  transition: none;

  &:hover {
    background-color: ${props => path(layout, props).bgColorHover};
    border: 1px solid ${props => path(layout, props).borderColorHover};
    color: ${props => path(layout, props).fontColorHover};
    transition: background-color 0.15s ease-in;
  }

  &:active {
    background-color: ${props => path(layout, props).bgColorActive};
    border: 1px solid ${props => path(layout, props).borderColorActive};
    color: ${props => path(layout, props).fontColorActive};
  }

  &:disabled {
    background-color: ${props => path(layout, props).bgColorDisabled};
    border: 1px solid ${props => path(layout, props).borderColorDisabled};
    color: ${props => path(layout, props).fontColorDisabled};
  }
`;

const computeVariant = (variant, layout) => (layout.light[variant] ? variant : "primary");
const path = (layout, props) => layout[props.theme.name || "light"][props.variant];

const Button = props => {
  const variant = computeVariant(props.variant, layout);
  return (
    <StyledButton {...props} className={`cg-common cg-button ${variant} ${props.className || ""}`} variant={variant}>
      {props.children}
    </StyledButton>
  );
};

export default Button;
