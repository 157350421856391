import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { POST_LOGOUT, POST_REMOVE_MFA } from "../../graphql/queries";
import useWindowSize from "../../hooks/useWindowSize";
import { Store } from "../../stores/stores";
import ButtonComponent from "../styled/button";

export const MenuEntries = ({ location }) => {
  return (
    <div className="entries">
      <ul className="show">
        <li className={location.pathname.includes("/invoices") ? "active" : ""}>
          <Link to="/invoices">
            <i className="icon-collection" /> Invoices
          </Link>
        </li>
        <li className={location.pathname === "/configs" ? "active" : ""}>
          <Link to="/configs">
            <i className="icon-collection" /> Configs
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default function Navbar(props) {
  const windowSize = useWindowSize(1080);
  const { addNotification } = Store.useState(s => s);
  const [logout] = useMutation(POST_LOGOUT);
  const [removeMfa] = useMutation(POST_REMOVE_MFA);

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem("jwt");
      Store.update(s => {
        s.session = undefined;
      });
    } catch (e) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  if (windowSize.isMobile) return <Mobile onChangeConfig={[]} session={{}} config={{}} logout={() => {}} {...props} />;
  return (
    <nav id="navbar">
      <div className="left"></div>
      <div className="right">
        <ButtonComponent type="button" variant="danger" style={{ marginRight: "10px" }} onClick={removeMfa}>
          Reset MFA
        </ButtonComponent>
        <ButtonComponent type="button" variant="secondary" onClick={handleLogout}>
          Logout
        </ButtonComponent>
      </div>
    </nav>
  );
}

function Mobile() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [logout] = useMutation(POST_LOGOUT);
  const [removeMfa] = useMutation(POST_REMOVE_MFA);
  const { addNotification } = Store.useState(s => s);

  const handleLogout = async () => {
    try {
      localStorage.removeItem("jwt");
      Store.update(s => {
        s.session = undefined;
      });
      await logout();
    } catch (e) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  useEffect(() => {
    if (isOpen) document.body.classList.add("noScroll");
    else document.body.classList.remove("noScroll");
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <>
      <div id="mobileNavbarContainer">
        <div id="mobileNavbar">
          <ul id="mobileNavbarList">
            <li>
              <button type="button" className="burger reset" onClick={e => setIsOpen(!isOpen)}>
                {!isOpen ? <i className="icon-burger" /> : <i className="icon-close" />}
              </button>
            </li>
            <li id="removeMfa">
              <button type="button" className="reset" onClick={removeMfa}>
                Reset MFA
              </button>
            </li>
            <li id="logout">
              <a
                href="/logout"
                onClick={e => {
                  e.preventDefault();
                  handleLogout();
                }}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={`mobileMainMenu ${isOpen ? "open" : "closed"}`}>
        <button type="button" className="close" onClick={e => setIsOpen(!isOpen)}>
          <i className="icon-close" />
        </button>
        <MenuEntries location={location} />
      </div>
    </>
  );
}
