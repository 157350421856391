import React, { useEffect, useState } from "react";
import Button from "../components/styled/button";
import Input from "../components/styled/input";
import { Select } from "../components/styled/select";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_CONFIGS,
  GET_PLANS,
  POST_CONFIG_PLAN,
  POST_CONFIG_SITES,
  POST_CONFIG_STATUS,
  POST_CONFIG_DELETE,
  POST_CONFIG_FEED_STATUS
} from "../graphql/queries";
import Loader from "../components/global/loader";
import moment from "moment";
import { Store } from "../stores/stores";
import { cloneDeep } from "@apollo/client/utilities";

const Configs = () => {
  const addNotification = Store.useState(s => s.addNotification);
  const { loading: plansIsLoading, error: plansError, data: plansData } = useQuery(GET_PLANS);
  const [getConfigs, { error: configsError, loading: configsIsLoading, data: configsData, refetch: refetchConfigs }] =
    useLazyQuery(GET_CONFIGS);
  const plans = plansData?.plans?.edges?.map(e => e.node);
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({ status: "active" });

  useEffect(() => {
    if (plans)
      Store.update(s => {
        s.plans = plans;
      });
  }, [plans]);

  useEffect(() => {
    if (configsError) addNotification({ ok: 0, message: configsError.toString() });
  }, [configsError, addNotification]);

  useEffect(() => {
    if (plansError) addNotification({ ok: 0, message: plansError.toString() });
  }, [plansError, addNotification]);

  useEffect(() => {
    getConfigs({ variables: filters });
  }, [getConfigs, filters]);

  const handleStatusChange = e => {
    const newFilters = { ...filters, status: e.value };
    setFilters(newFilters);
    getConfigs({ variables: newFilters });
  };

  if (configsIsLoading || plansIsLoading) return <Loader />;

  const status = [
    {
      label: "Active",
      value: "active"
    },
    {
      label: "Inactive",
      value: "inactive"
    },
    {
      label: "Archived",
      value: "archived"
    }
  ];

  const handleSubmitSearch = e => {
    e.preventDefault();
    getConfigs({ variables: { search: searchValue } });
  };

  const handleSearch = e => {
    setSearchValue(e.target.value);
  };

  if (!configsData) return <Loader />;

  return (
    <div id="configs">
      <section className="header">
        <h1>Configs {configsData ? `(${configsData.configsConnection.configs.length})` : ""}</h1>
      </section>
      <section className="filters">
        <div className="filter">
          <Select
            label="Status"
            value={{ label: filters.status }}
            options={status}
            onChange={handleStatusChange}
            className="status"
          />
        </div>
        <div className="filter">
          <form onSubmit={handleSubmitSearch}>
            <Input
              label="Search config"
              placeholder="Search through configs"
              value={searchValue}
              type="text"
              onChange={handleSearch}
              className="status"
            />
          </form>
        </div>
      </section>
      <section className="content">
        <div className="configsTable">
          {configsData.configsConnection.configs.map((config, i) => (
            <ConfigEntry key={config._id} config={config} refetchConfigs={refetchConfigs} i={i} />
          ))}
        </div>
      </section>
    </div>
  );
};
const getFlagEmoji = countryCode =>
  String.fromCodePoint(...[...countryCode.toUpperCase()].map(x => 0x1f1a5 + x.charCodeAt()));

const ConfigEntry = ({ config, refetchConfigs }) => {
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  let className = config.status.active ? "active" : "inactive";
  const nextPaymentDate = config.plan && config.plan.nextPaymentDate;
  if (!nextPaymentDate && config.status.active) className = "active-nopayment";
  else if (nextPaymentDate && moment(config.plan.nextPaymentDate).isBefore(moment().subtract(1, "day")))
    className = "active-nopayment";
  const image = config.modules.find(m => m.id === "designs").data.logoImage;
  return (
    <div className="entry">
      <div className="header">
        <p className={className}>#{config.id}</p>
        <div>
          {image ? (
            <a href={config.uri} target="_blank" rel="noreferrer">
              <img src={image} alt="logo" />
            </a>
          ) : null}
        </div>
        <div>
          <a href={config.uri} target="_blank" rel="noreferrer">
            {config.shopName}
          </a>
          <p>
            <small>
              Created: {moment(config.created).fromNow()} ({moment(config.created).format("ll")})<br />
              by {config.admins[0]?.admin.email}
            </small>
          </p>
          <p>
            <small>
              Country: {config.taxes?.country.name} {getFlagEmoji(config.taxes.country.code)}
            </small>
          </p>
          <p>
            <small>
              Available seats: {config.plan.seats - config.admins.length}/{config.plan.seats}
            </small>
          </p>
          <p>
            <small>Database ID: {config.storage?.database?.name}</small>
          </p>
          <p>
            <small>Feed status: {config.community.feed.enabled ? "Enabled" : "-"}</small>
          </p>
          <p>
            <small>
              Eshop generation:{" "}
              {config.eshop?.theme?.generation === 2 ? <span>V2</span> : <span className="danger">V1</span>}
            </small>
          </p>
        </div>
        <div>
          <p>
            {config.plan?.title || ""}{" "}
            <span className="grey">
              {config.plan && config.plan.nextPaymentDate ? `(${moment(config.plan.nextPaymentDate).fromNow()})` : ""}
            </span>
          </p>
          {config.plan?.trial?.active ? (
            <p className="grey">In trial ({moment(config.plan.nextPaymentDate).diff(moment(), "days")} day left)</p>
          ) : null}
        </div>
        <div className={className}>{className}</div>
        <div>
          <Button variant="secondary" type="button" onClick={() => setIsBeingEdited(!isBeingEdited)}>
            Edit
          </Button>
        </div>
      </div>
      {isBeingEdited ? (
        <div className="content">
          <ConfigPlan config={config} refetchConfigs={refetchConfigs} />
          <ConfigSites config={config} refetchConfigs={refetchConfigs} />
          <ConfigStatus config={config} refetchConfigs={refetchConfigs} />
          <AdminList config={config} />
          <AddToFeed config={config} refetchConfigs={refetchConfigs} />
        </div>
      ) : null}
    </div>
  );
};

const AddToFeed = ({ config, refetchConfigs }) => {
  console.log(config.community.feed.enabled);
  const status = config.community.feed.enabled;

  const [updateFeed] = useMutation(POST_CONFIG_FEED_STATUS);

  const handleStatusUpdate = async status => {
    await updateFeed({ variables: { configRef: config._id, status } });
    // refetchConfigs();
  };

  return (
    <div className="addToFeed">
      {status ? (
        <Button type="button" variant="danger" onClick={e => handleStatusUpdate(!status)}>
          Remove from Feed
        </Button>
      ) : (
        <Button variant="secondary" type="button" onClick={e => handleStatusUpdate(!status)}>
          Add to feed
        </Button>
      )}
    </div>
  );
};

const ConfigDelete = ({ config, refetchConfigs }) => {
  const addNotification = Store.useState(s => s.addNotification);
  const [deleteConfig, { loading }] = useMutation(POST_CONFIG_DELETE);
  const [isBeingDeleted, setIsBeingDeleted] = useState(false);

  const onSubmitDelete = async e => {
    e.preventDefault();
    try {
      await deleteConfig({ variables: { configRef: config._id, token: e.target.token.value } });
      refetchConfigs();
      addNotification({ ok: 1, message: `${config.shopName} has been archived and will be deleted in 3 months` });
    } catch (error) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  if (config.status.active) return null;

  return (
    <div className="deleteConfig">
      {isBeingDeleted ? (
        <>
          <form onSubmit={onSubmitDelete} className="deleteForm">
            <input className="input2FA" name="token" required placeholder="Please type 2FA token" />
            <button className="cancelButton" type="button" onClick={() => setIsBeingDeleted(false)}>
              Cancel
            </button>
            <button className="deleteButton" variant="secondary" type="submit" disabled={loading}>
              {loading ? <Loader /> : "Confirm"}
            </button>
          </form>
        </>
      ) : (
        <Button variant="danger" onClick={() => setIsBeingDeleted(true)}>
          Archive config
        </Button>
      )}
    </div>
  );
};

const ConfigPlan = ({ config, refetchConfigs }) => {
  const addNotification = Store.useState(s => s.addNotification);
  const [updateConfigPlan, { loading }] = useMutation(POST_CONFIG_PLAN);
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [plan, setPlan] = useState(cloneDeep(config.plan));
  const plans = Store.useState(s => s.plans);

  const handleSubmit = async e => {
    e.preventDefault();
    if (moment(plan.nextPaymentDate).isBefore(moment()))
      return addNotification({ ok: 0, message: "Next payment date cannot be anterior" });

    try {
      const { data } = await updateConfigPlan({
        variables: {
          configRef: config._id,
          plan: {
            ...plan,
            nextPaymentDate: plan.nextPaymentDate ? moment(plan.nextPaymentDate).utcOffset(0, true).format() : null
          },
          sendNotification: e.target.sendNotification.checked
        }
      });
      setPlan(data.configUpdatePlan.plan);
      refetchConfigs();
      addNotification({ ok: 1, message: "Plan updated" });
      setIsBeingEdited(false);
    } catch (e) {
      console.log(e);
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  const updatePlan = updatedFields => setPlan({ ...plan, ...updatedFields });

  const onClickEdit = e => {
    e.preventDefault();
    setIsBeingEdited(!isBeingEdited);
  };

  const onClickCancel = () => {
    setIsBeingEdited(false);
    setPlan(cloneDeep(config.plan));
  };

  const clearPaymentDate = e => {
    e.preventDefault();
    updatePlan({ nextPaymentDate: undefined });
  };

  const handleTrialChange = e => {
    updatePlan({ trial: { ...plan.trial, active: e.target.checked } });
  };

  const onChangePlan = plan =>
    updatePlan({
      title: plan.title,
      seats: plan.seats,
      price: plan.price,
      trial: plan.trial,
      recurrence: plan.recurrence
    });

  return (
    <form onSubmit={handleSubmit} className="plan">
      <div className="formGroup">
        <label>Plan</label>
        <Select
          readOnly={!isBeingEdited}
          value={{ label: plan?.title }}
          onChange={e => onChangePlan(plans.find(p => p.title === e.value))}
          className="title noBorderOnDisabled"
          placeholder={isBeingEdited ? "Select..." : "-"}
          options={plans?.map(p => ({
            label: p.title,
            value: p.title
          }))}
        />
      </div>
      <div className="formGroup">
        <label>Seats</label>
        <Input
          variant="overZone"
          className="noBorderOnDisabled"
          onChange={e => updatePlan({ seats: e.target.value })}
          type="number"
          disabled={!isBeingEdited}
          name="seats"
          value={plan?.seats || ""}
        />
      </div>
      <div className="formGroup">
        <label>Price</label>
        <Input
          variant="overZone"
          className="noBorderOnDisabled"
          onChange={e => updatePlan({ price: { ...plan.price, value: e.target.value } })}
          type="number"
          disabled={!isBeingEdited}
          name="priceValue"
          value={plan?.price?.value || ""}
        />
      </div>
      <div className="formGroup">
        <label>Next Payment Date</label>
        <div className="nextPayment">
          <Input
            variant="overZone"
            className="noBorderOnDisabled"
            onChange={e => updatePlan({ nextPaymentDate: e.target.value })}
            type="date"
            disabled={!isBeingEdited}
            name="nextPaymentDate"
            value={plan?.nextPaymentDate ? moment(plan.nextPaymentDate).utcOffset(0, true).format("YYYY-MM-DD") : ""}
          />
          {isBeingEdited ? <i className="icon-close clearNextPayment" onClick={clearPaymentDate}></i> : null}
        </div>
      </div>
      <div className="formGroup">
        <label>Recurrence</label>
        <Select
          readOnly={!isBeingEdited}
          value={{ label: plan?.recurrence }}
          onChange={e => updatePlan({ recurrence: e.value })}
          className="recurrence noBorderOnDisabled"
          placeholder={isBeingEdited ? "Select..." : "-"}
          options={[
            { label: "Monthly", value: "monthly" },
            { label: "Yearly", value: "yearly" }
          ]}
        />
      </div>
      {isBeingEdited && (
        <div className="formGroup">
          <label>Send Notification</label>
          <input type="checkbox" name="sendNotification" />
        </div>
      )}
      <div className="formGroup">
        <label>Trial</label>
        <label>
          <input
            type="checkbox"
            className="noBorderOnDisabled"
            onChange={handleTrialChange}
            disabled={!isBeingEdited}
            name="inTrial"
            checked={plan?.trial?.active}
          />
          Trial
        </label>
      </div>
      <div className="buttons">
        {isBeingEdited ? (
          <>
            <Button type="button" onClick={onClickCancel}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              {loading ? <Loader /> : "Submit"}
            </Button>
          </>
        ) : (
          <Button variant="secondary" type="button" onClick={onClickEdit}>
            Edit Plan
          </Button>
        )}
      </div>
    </form>
  );
};

const ConfigSites = ({ config, refetchConfigs }) => {
  const addNotification = Store.useState(s => s.addNotification);
  const [sites, setSites] = useState(cloneDeep(config.sites));
  const [updateConfigSites, { loading }] = useMutation(POST_CONFIG_SITES);

  const addSite = e => {
    e.preventDefault();
    sites.push({ domain: "", isPrimary: false });
    setSites([...sites]);
  };
  const deleteSite = i => {
    if (sites.length > 1) {
      sites.splice(i, 1);
      setSites([...sites]);
    } else addNotification({ ok: 0, message: "Config must have at least one domain" });
  };
  const onChangeDomain = (e, i) => {
    e.preventDefault();
    sites[i].domain = e.target.value.trim();
    setSites([...sites]);
  };
  const onChangePrimary = e => {
    if (e.target.name === "primary")
      setSites(
        sites.map(site => {
          site.isPrimary = site.domain === e.target.value;
          return site;
        })
      );
  };

  const onClickSubmit = async e => {
    e.preventDefault();
    const sitesToSubmit = sites.filter(s => s.domain);
    if (!sitesToSubmit.length) return addNotification({ ok: 0, message: "Config must have at least one domain" });
    else if (!sitesToSubmit.find(s => s.domain.includes(".common-ground.io")))
      return addNotification({ ok: 0, message: "You must keep the default subdomain" });
    else if (sitesToSubmit.length === 1) sitesToSubmit[0].isPrimary = true;
    else if (!sitesToSubmit.find(s => s.isPrimary))
      return addNotification({ ok: 0, message: "Please select a primary domain" });

    try {
      const { data } = await updateConfigSites({ variables: { configRef: config._id, sites: sitesToSubmit } });
      setSites(cloneDeep(data.configUpdateSites.sites));
      refetchConfigs();
      addNotification({ ok: 1, message: "Sites updated" });
    } catch (e) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  return (
    <div className="sites">
      <div className="title">Domains</div>
      <form className="siteList" onSubmit={onClickSubmit}>
        {sites.map((s, i) => (
          <div key={i} className="siteEntry">
            <input
              onChange={e => onChangeDomain(e, i)}
              name="domain"
              type="text"
              required
              pattern="([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+"
              value={s.domain}
              className="siteDomain"
            />
            <input type="radio" value={s.domain} name="primary" onChange={onChangePrimary} checked={s.isPrimary} />
            <i onClick={e => deleteSite(i)} className="icon-close" />
          </div>
        ))}
        <div className="buttons">
          <Button variant="secondary" type="button" onClick={addSite}>
            Add
          </Button>
          <Button type="submit" variant="secondary" disabled={loading}>
            {loading ? <Loader /> : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
};

const ConfigStatus = ({ config, refetchConfigs }) => {
  const addNotification = Store.useState(s => s.addNotification);
  const [status, setStatus] = useState(config.status.active);
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [updateConfigStatus, { loading }] = useMutation(POST_CONFIG_STATUS);

  const onChangeStatus = state => setStatus(state);

  const onClickCancel = () => {
    setIsBeingEdited(false);
    setStatus(config.status.active);
  };

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const { data } = await updateConfigStatus({ variables: { configRef: config._id, state: status } });
      setStatus(data.configUpdateStatus.status.active);
      addNotification({ ok: 1, message: "Status updated" });
      setIsBeingEdited(false);
    } catch (error) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  return (
    <div className="status">
      <form onSubmit={onSubmit}>
        <div>Status</div>
        <div>
          <Select
            readOnly={!isBeingEdited}
            value={{ label: status ? "Active" : "Inactive" }}
            onChange={e => onChangeStatus(e.value)}
            className="noBorderOnDisabled"
            options={[
              { label: "Active", value: true },
              { label: "Inactive", value: false }
            ]}
          />
        </div>
        <div className="buttons">
          {isBeingEdited ? (
            <>
              <Button variant="secondary" type="button" onClick={onClickCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={loading}>
                {loading ? <Loader /> : "Submit"}
              </Button>
            </>
          ) : (
            <Button
              variant="secondary"
              className="editStatusButton"
              type="button"
              onClick={() => setIsBeingEdited(true)}>
              Edit Status
            </Button>
          )}
        </div>
      </form>
      <ConfigDelete config={config} refetchConfigs={refetchConfigs} />
    </div>
  );
};

const AdminList = ({ config, addNotification, session, isMobile }) => {
  const [newAdmin, setNewAdmin] = useState(undefined);

  const handleCancelNewAdmin = () => setNewAdmin(undefined);

  return (
    <div className="shopAdmins">
      <div className="adminList">
        {newAdmin ? (
          <Admin user={newAdmin} handleCancelNewAdmin={handleCancelNewAdmin} isNew addNotification={addNotification} />
        ) : null}
        {config &&
          config.admins.map((u, i) => (
            <React.Fragment key={u.admin._id}>
              <Admin user={u} session={session} addNotification={addNotification} />
              {i < config.admins.length - 1 ? <hr /> : null}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

const Admin = ({ user, session, handleRemoveAdmin, handleAddAdmin, addNotification, isNew, handleCancelNewAdmin }) => {
  return (
    <div className="admin">
      <p>
        {user.admin.name}
        <span className="email">{user.admin.email}</span>
      </p>
      <p className="added">{user.added ? <>Added {moment(user.added.date).format("ll")}</> : null}</p>
    </div>
  );
};

export default Configs;
