import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Input } from "@common-ground-io/common-assets/react/";
import Loader from "../components/global/loader";
import { GET_DICTIONARY, POST_DICTIONARY_UPDATE } from "../graphql/queries";
import { Store } from "../stores/stores";
import Clone from "clone";
import Modal from "react-modal";
import { colorsAsRgbString } from "@common-ground-io/colors";
import Button from "../components/styled/button";

const languages = require("language-list")();

export default function Dictionary(props) {
  const { addNotification } = Store.useState(s => s);
  const [newKey, setNewKey] = useState("");
  const [keys, setKeys] = useState("");
  const [dirty, setDirty] = useState(false);
  const [copiedDictionary, setCopiedDictionary] = useState();
  const [selectedKey, setSelectedKey] = useState(null);
  const { data, loading } = useQuery(GET_DICTIONARY, { variables: { dictionaryReference: props.match.params.ref } });
  const [saveDictionary, { loading: saving }] = useMutation(POST_DICTIONARY_UPDATE);
  const [isAddingKey, setIsAddingKey] = useState(false);
  const [term, setTerm] = useState("");

  const generateKeysForEditor = () => {
    if (!copiedDictionary) return;
    const keys = {};
    copiedDictionary.languages.forEach(l => {
      Object.keys(l.keys)
        .sort()
        .forEach(key => {
          if (!keys[key] && typeof l.keys[key] === "string") keys[key] = "";
        });
    });
    setKeys(keys);
  };

  useEffect(() => {
    if (data) setCopiedDictionary(Clone(data.dictionary));
  }, [data]);

  const handleSave = async () => {
    await saveDictionary({
      variables: { dictionaryReference: copiedDictionary._id, dictionaryData: copiedDictionary }
    });
    setDirty(false);
    addNotification({ ok: 1, message: "Dictionary updated" });
  };

  const handleSelectedKeyChange = (locale, key, value) => {
    const foundLanguage = copiedDictionary.languages.find(l => l.locale === locale);
    foundLanguage.keys[key] = value;
    setDirty(true);
    setCopiedDictionary(Clone(copiedDictionary));
  };

  const handleAddKey = e => {
    setDirty(true);
    e.preventDefault();
    copiedDictionary.languages.forEach(l => {
      l.keys[newKey] = newKey;
    });
    setCopiedDictionary(Clone(copiedDictionary));
    setSelectedKey(newKey);
    generateKeysForEditor();
    setNewKey("");
    addNotification({ ok: 1, message: "Key added" });
    setIsAddingKey(false);
    handleSave();
    setTerm("");
  };

  const handleDeleteKey = keyString => {
    setDirty(true);
    copiedDictionary.languages.forEach(l => {
      delete l.keys[keyString];
    });
    setCopiedDictionary(Clone(copiedDictionary));
    generateKeysForEditor();
  };

  if (loading || !copiedDictionary) return <Loader />;

  const toFindDuplicates = arry => arry.filter((item, index) => arry.indexOf(item) !== index);

  const getEntriesForKey = key => {
    const entries = [];
    copiedDictionary.languages.forEach(l => {
      const value = l.keys[key];
      entries.push(value);
    });
    return entries;
  };

  const countSimilarKeys = key => {
    const entries = getEntriesForKey(key);
    const duplicates = toFindDuplicates(entries);
    return duplicates.length;
  };

  const countMissingKeys = key => {
    const entries = getEntriesForKey(key);
    return copiedDictionary.languages.length - entries.length;
  };

  const handleSearch = e => {
    setTerm(e.target.value);
  };

  const KeySelector = ({ keyString }) => {
    const similar = countSimilarKeys(keyString);
    const missing = countMissingKeys(keyString);
    const selected = selectedKey === keyString;
    return (
      <div className="keyEditor">
        <Button
          variant={selected ? "primary" : "secondary"}
          type="button"
          className="entry"
          onClick={e => setSelectedKey(keyString)}>
          {keyString} {similar ? <span className="duplicates">{similar}</span> : null}
          {missing ? <span> - </span> : null}
          {missing ? <span className="missing">{missing}</span> : null}
        </Button>
        <Button variant="danger" onClick={e => handleDeleteKey(keyString)}>
          Delete
        </Button>
      </div>
    );
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      minWidth: "30%",
      transform: "translate(-50%, -50%)"
    }
  };

  if (!keys) generateKeysForEditor();
  if (!selectedKey && keys) setSelectedKey(Object.keys(keys)[0]);

  return (
    <div id="dictionary">
      <Modal style={customStyles} isOpen={isAddingKey} onRequestClose={e => setIsAddingKey(false)}>
        <form
          className="newKey"
          onSubmit={handleAddKey}
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Input
            label="New key"
            required
            autoFocus={true}
            value={newKey}
            onChange={e => setNewKey(e.target.value)}
            placeholder="Enter new key name"
          />
          <Button type="submit" className="addNewKey" variant="primary">
            Submit
          </Button>
        </form>
      </Modal>
      <section className="header">
        <div className="summary">
          <h1>{copiedDictionary.title}</h1>
          <p className="languages">
            {copiedDictionary.languages.map(l => (
              <span key={l.locale}>{l.locale} </span>
            ))}{" "}
            - {Object.keys(keys).length} keys
          </p>
          <Button
            type="submit"
            className="addNewKey"
            variant="primary"
            disabled={saving}
            onClick={() => setIsAddingKey(true)}>
            Add new key
          </Button>
        </div>
        <div className="right">
          <div>
            <Button type="button" disabled={saving || !dirty} onClick={handleSave}>
              {saving ? <Loader /> : <>Save dictionary {dirty ? "*" : ""}</>}
            </Button>
          </div>
        </div>
      </section>

      <div className="editor">
        <div className="left">
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
              top: "0px",
              padding: "10px",
              backgroundColor: colorsAsRgbString.greyLightest
            }}>
            <Input name="term" variant="overZone" placeholder="Filter keys..." onChange={handleSearch} value={term} />
            <div>
              <Button type="button" onClick={e => setTerm("")} variant={term ? "primary" : "secondary"}>
                Clear
              </Button>
            </div>
          </div>
          {Object.keys(keys)
            .filter(k => k.toLowerCase().includes(term.toLowerCase()))
            .map(k => (
              <KeySelector key={k} keyString={k} />
            ))}
        </div>
        <div className="right">
          {selectedKey ? (
            <div className="choices">
              {copiedDictionary.languages.map(l => (
                <div className="choice" key={l.locale}>
                  <label>
                    {languages.getLanguageName(l.locale)}
                    <Input
                      onChange={e => handleSelectedKeyChange(l.locale, selectedKey, e.target.value)}
                      value={l.keys[selectedKey]}
                    />
                  </label>
                </div>
              ))}
            </div>
          ) : (
            <p>Select a key</p>
          )}
        </div>
      </div>
    </div>
  );
}
