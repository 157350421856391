import React, { useState, useEffect } from "react";
// import "chart.js";
import Loader from "../components/global/loader";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_DASHBOARD } from "../graphql/queries";
// import { Bar, Line, Doughnut } from "react-chartjs-2";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { GET_CONFIGS_FOR_MAPS } from "../graphql/queries";

const Dashboard = () => {
  const [period, setPeriod] = useState(localStorage.getItem("dashboard-period") || 24);

  const [getDashboard, { data: dashboardData, loading }] = useLazyQuery(GET_DASHBOARD, {
    variables: { period: parseInt(period) }
  });

  // const options = {
  //   scales: {
  //     x: { grid: { display: false } },
  //     y: { grid: { display: false } }
  //   }
  // };

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  const handlePeriodChange = async value => {
    setPeriod(value);
    localStorage.setItem("dashboard-period", value);
    getDashboard({ variables: { perdiod: value } });
  };

  if (loading || !dashboardData) return <Loader />;

  // const periods = [4, 12, 24, 36];
  return (
    <div id="dashboard">
      <section className="header">
        <h1>Dashboard</h1>
        {/* <div className="right">
          <form onSubmit={e => e}>
            {periods.map(p => (
              <label key={String(p)}>
                <input
                  onChange={e => handlePeriodChange(e.target.value)}
                  type="radio"
                  name="period"
                  value={p}
                  checked={String(period) === String(p)}
                />
                {p} months
              </label>
            ))}
          </form>
        </div> */}
      </section>
      <section className="content">
        <div className="top">
          {/* {dashboardData.dashboard?.graphs?.ordersOverTime ? (
            <div className="ordersOverTime">
              <Bar data={dashboardData.dashboard.graphs.ordersOverTime.data} options={options} />
            </div>
          ) : null}
          <div className="configsOverTime">
            <Bar data={dashboardData.dashboard.graphs.configsOverTime.data} options={options} />
          </div>
          {dashboardData.dashboard.graphs.itemsOverTime ? (
            <div className="itemsOverTime">
              <Bar data={dashboardData.dashboard.graphs.itemsOverTime.data} options={options} />
            </div>
          ) : null}
          <div className="snippetsOverTime">
            <Bar data={dashboardData.dashboard.graphs.snippetsOverTime.data} options={options} />
          </div>
          {dashboardData.dashboard.graphs.healthOverTime ? (
            <div className="healthOverTime">
              <Line data={dashboardData.dashboard.graphs.healthOverTime.data} options={options} />
            </div>
          ) : null}
          {dashboardData.dashboard.graphs.salesOrigin ? (
            <div className="salesOrigin">
              <Doughnut data={dashboardData.dashboard.graphs.salesOrigin.data} options={options} />
            </div>
          ) : null} */}
        </div>
        <div className="bottom">
          <div className="block">
            <h3>Billed configs</h3>
            <h2>{dashboardData.dashboard.statistics.activeConfigs}</h2>
          </div>
          <div className="block">
            <h3>Trial configs</h3>
            <h2>{dashboardData.dashboard.statistics.trialConfigs}</h2>
          </div>
          <div className="block">
            <h3>Deactivated configs</h3>
            <h2>{dashboardData.dashboard.statistics.deactivatedConfigs}</h2>
          </div>
          <div className="block">
            <h3>Active admins seats</h3>
            <h2>{dashboardData.dashboard.statistics.totalSeats}</h2>
          </div>
        </div>
        <Maps />
      </section>
    </div>
  );
};

export default Dashboard;

const Maps = () => {
  const { data, loading } = useQuery(GET_CONFIGS_FOR_MAPS, { variables: { status: "active" } });

  if (loading) return null;

  const plots = data.configsConnection.configs
    .map(c => {
      const m = c.modules.find(m => m.id === "information");
      const d = c.modules.find(m => m.id === "designs");
      if (m.data.shopAddressCoordinates)
        return { coordinates: m.data.shopAddressCoordinates, name: m.data.shopName, icon: d.data.logoImage };
      else return null;
    })
    .filter(c => !!c);

  return (
    <LoadScript googleMapsApiKey="AIzaSyBW8F_sFJnA2gD7FZK4w0_X6-6xtqZa8_E">
      <GoogleMap mapContainerStyle={{ width: "100%", height: "600px" }} center={{ lat: -1.745, lng: -8.523 }} zoom={2}>
        {plots.map((p, i) => (
          <Marker
            key={i}
            icon={{
              url: p.icon || "https://static.common-ground.io/shops/1/media/600-px-iOSn4OX6hb.png",
              size: { width: 60, height: 60 },
              anchor: { x: 15, y: 50 },
              scaledSize: { width: 30, height: 30 }
            }}
            position={p.coordinates}
            label={p.name}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};
