import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Loader from "../components/global/loader";
import { GET_DICTIONARIES } from "../graphql/queries";
import Button from "../components/styled/button";
const languages = require("language-list")();

export default function Dictionaries() {
  const { data, loading } = useQuery(GET_DICTIONARIES);

  if (loading || !data) return <Loader />;

  return (
    <div id="dictionaries">
      <section className="header">
        <h1>Dictionaries</h1>
      </section>
      <div className="content">
        {data.dictionaries.dictionaries.map(d => (
          <div key={d.title} className="dictionary">
            <h2>{d.title}</h2>
            <p className="languages">
              {d.languages.map(l => (
                <span key={l}>{languages.getLanguageName(l.locale)} </span>
              ))}
            </p>
            <Link to={`/dictionary/${d._id}`}>
              <Button type="button">Edit</Button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
