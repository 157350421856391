import React, { useState, useEffect } from "react";
import { useHistory, Link, Prompt } from "react-router-dom";
import { Store } from "../stores/stores";

import moment from "moment";

import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import clone from "clone";
import Modal from "react-modal";
import Loader from "../components/global/loader";
import Four0Four from "../components/global/404";
import Button from "../components/styled/button";
import Checkbox from "../components/styled/checkbox";
import DatePicker from "../components/styled/datePicker";
import InputWithSubmit from "../components/styled/inputWithSubmit";
import Input from "../components/styled/input";
import { GET_LISTS } from "../graphql/queries";
import {
  GET_CAMPAIGN,
  POST_CAMPAIGN_SEND,
  POST_CAMPAIGN_SET_IS_NEWS,
  POST_CAMPAIGN_TEST_EMAIL,
  POST_CAMPAIGN_UPDATE
} from "../graphql/queries/campaign";
import { Select, SelectTemplate } from "../components/styled/select";

export default function Campaign(props) {
  const { addNotification } = Store.useState(c => c);
  const { data: listData } = useQuery(GET_LISTS);
  const lists = listData?.lists?.lists;

  const history = useHistory();
  const [campaign, setCampaign] = useState(undefined);
  const [metrics, setMetrics] = useState(undefined);
  const [testEmails, setTestEmails] = useState("");
  const [sendingDate, setSendingDate] = useState();
  const [sendAtTime, setSendAtTime] = useState(false);
  const [hasConfirmedSending, setHasConfirmedSending] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
  const [isSavingOrCreating, setIsSavingOrCreating] = useState(false);
  const [isSendingCampaign, setisSendingCampaign] = useState(false);
  const [getCampaign] = useLazyQuery(GET_CAMPAIGN, { fetchPolicy: "cache-and-network" });
  const [updateCampaign] = useMutation(POST_CAMPAIGN_UPDATE);
  const [setIsNews] = useMutation(POST_CAMPAIGN_SET_IS_NEWS);
  const [sendCampaign] = useMutation(POST_CAMPAIGN_SEND);
  const [sendTestEmail] = useMutation(POST_CAMPAIGN_TEST_EMAIL);
  const [isSending, setIsSending] = useState(false);

  document.title = "Campaign";
  const campaignRef = props.match.params.ref;

  useEffect(() => {
    const getData = async () => {
      const { data } = await getCampaign({ variables: { campaignRef } });
      if (data?.campaign) {
        setCampaign(clone(data.campaign.campaign));
        setMetrics(data.campaign.metrics);
      }
    };
    getData();
  }, [campaignRef, getCampaign]);

  const handleSelectTemplate = async template => {
    console.log(template);
    try {
      campaign.template = clone(template);
      setCampaign({ ...campaign });
      setDirty(true);
    } catch (e) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleSelectList = option => {
    campaign.list = option.value;
    setCampaign({ ...campaign });
    setDirty(true);
  };

  const handleTitleChange = event => {
    campaign.title = event.target.value;
    setCampaign({ ...campaign });
    setDirty(true);
  };

  const handleSubmitCampaignForm = async event => {
    event.preventDefault();
    if (!campaign || !campaign.list) return addNotification({ ok: 0, message: "A list of recipients is required" });
    setIsSavingOrCreating(true);
    const campaignData = {
      title: campaign.title,
      listingRefs: campaign.entries.filter(e => e.listing && e.item).map(e => e.listing._id),
      listRef: campaign.list?._id,
      fields: campaign.fields,
      templateRef: campaign.template?._id
    };

    try {
      const { data } = await updateCampaign({
        variables: { campaignRef: campaign._id, campaignUpdateInput: campaignData }
      });
      setCampaign(clone(data.campaign));
      addNotification({ ok: 1, message: "Campaign updated" });
    } catch (e) {
      addNotification({ ok: 0, message: e.message });
    }
    setDirty(false);
    setIsSavingOrCreating(false);
  };

  const handleSendToList = async e => {
    e.preventDefault();
    if (sendAtTime && !sendingDate) return addNotification({ ok: 0, message: "Please specify date and time" });
    if (sendAtTime && moment(sendingDate).isBefore(moment()))
      return addNotification({ ok: 0, message: "Date must be in the future" });
    setisSendingCampaign(true);
    try {
      await sendCampaign({
        variables: {
          campaignRef: campaign._id,
          sendAt: sendAtTime ? sendingDate : null
        }
      });
      addNotification({ ok: 1, message: "Campaign sent" });
      history.push("/campaigns");
    } catch (e) {
      addNotification({ ok: 0, message: e.message });
    }
    setisSendingCampaign(false);
  };

  const handleSendTestEmail = async e => {
    e.preventDefault();
    setIsSendingTestEmail(true);
    try {
      await sendTestEmail({
        variables: {
          campaignRef: campaign._id,
          emails: testEmails.split(",")
        }
      });
      addNotification({ ok: 1, message: "Test emails sent" });
      setTestEmails("");
    } catch (e) {
      addNotification({ ok: 0, message: e.message });
    }
    setIsSendingTestEmail(false);
  };

  const handleUpdateTestEmail = async e => {
    setTestEmails(e.target.value);
  };

  const addToCGNews = async state => {
    const { data } = await setIsNews({
      variables: { campaignRef: campaign._id, isNews: state }
    });
    setCampaign(clone(data.campaignSetIsNews));
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      minWidth: "50%",
      transform: "translate(-50%, -50%)"
    }
  };

  if (campaign === undefined) return <Loader style={{ marginTop: "var(--gutter)" }} />;
  else if (campaign === null) return <Four0Four />;

  return (
    <div id="campaign">
      <Modal style={customStyles} isOpen={isSending} onRequestClose={e => setIsSending(false)}>
        <div id="campaignModal" className="">
          <div className="header">
            <h2>Review & Send</h2>
            <Button variant="noStyle" onClick={e => setIsSending(false)}>
              Close
            </Button>
          </div>
          <div id="campaignModalContent">
            <div id="testEmail">
              <h3>Test email</h3>
              <form onSubmit={handleSendTestEmail}>
                <InputWithSubmit
                  fullWidth
                  label="Recipients separated by a coma"
                  name="testEmails"
                  required
                  disabled={isSendingTestEmail}
                  type="text"
                  value={testEmails}
                  onChange={handleUpdateTestEmail}
                  placeholder="contact@example.com,contact2@gmail.com"
                  submitText={isSendingTestEmail ? "Sending test email..." : "Send test email"}
                />
              </form>
            </div>
            <hr />
            <div id="sendCampaign">
              <h3>Ready to send?</h3>
              <p>
                {campaign.list
                  ? `This campaign will be sent to ${campaign.list.count} recipients`
                  : "No recipients list was selected"}
              </p>
              <div className="sendingTime">
                <Checkbox checked={sendAtTime} onChange={e => setSendAtTime(!sendAtTime)} label="Schedule for later" />
                {sendAtTime ? (
                  <DatePicker
                    dateFormat="dd/MM/yyyy hh:mm"
                    required
                    showTimeSelect={true}
                    value={sendingDate}
                    onChange={e => setSendingDate(moment(e).format())}
                  />
                ) : null}
              </div>
              {!hasConfirmedSending ? (
                <Button
                  type="button"
                  className="primary"
                  disabled={isSendingCampaign || !campaign.list}
                  onClick={e => setHasConfirmedSending(true)}>
                  <span>Proceed</span>
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="danger"
                  disabled={isSendingCampaign || !campaign.list}
                  onClick={e => handleSendToList(e)}>
                  <span> {isSendingCampaign ? "Submitting..." : `Send to ${campaign.list.count} recipients`} </span>
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Prompt when={dirty} message={location => "Changes were unsaved, are you sure?"} />
      <section className="header">
        <div className="left">
          <h1>Edit campaign</h1>
          <Link to="/campaigns">
            <Button variant="noStyle" type="button" disabled={isSavingOrCreating}>
              Back to campaigns
            </Button>
          </Link>
        </div>
        <div className="right">
          {campaign.status === "draft" ? (
            <div id="send">
              <Button
                variant={dirty ? "secondary" : "primary"}
                onClick={async e => {
                  try {
                    await handleSubmitCampaignForm(e);
                    setIsSending(true);
                  } catch (e) {
                    addNotification({ ok: 0, message: e.toString() });
                  }
                }}
                type="button"
                disabled={dirty || isSavingOrCreating}>
                {isSavingOrCreating ? "Saving..." : "Review & Send"}
              </Button>
            </div>
          ) : null}
          {campaign.status === "draft" ? (
            <div id="updateAndDelete">
              <Button className="primary" form="edit" type="submit" disabled={isSavingOrCreating}>
                {dirty ? "*" : ""}
                {isSavingOrCreating ? "Saving..." : "Save campaign"}
              </Button>
            </div>
          ) : null}
        </div>
      </section>
      {campaign.status === "sent" ? (
        <div id="report">
          <section>
            <Checkbox
              label="Published as CG News"
              checked={!!campaign.publication.isCGNews}
              onChange={e => addToCGNews(e.target.checked)}
            />
          </section>
          <div id="reportHeader">
            <div className="left">
              {metrics && campaign.list ? (
                <div className="metrics">
                  <h3>
                    This campaign was sent to {campaign.list.title} (#
                    {campaign.list.count}) - {moment(metrics.sendAt).fromNow()}
                  </h3>
                  <div className="stats">
                    <h2>Open rate: {parseInt(metrics.openRate)}%</h2>
                    <h2>Delivery rate: {parseInt(metrics.deliveryRate)}%</h2>
                    <h2>Bounce rate: {parseInt(metrics.bounceRate)}%</h2>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmitCampaignForm} id="edit">
          <div id="left">
            <div className="settings">
              <h2>Campaign settings</h2>
              <Input
                label="Campaign title"
                required
                onChange={handleTitleChange}
                placeholder="eg. News of the week, Latest releases..."
                value={campaign.title}
              />
              <div className="twoColumns">
                <SelectTemplate
                  value={campaign?.template ? { label: campaign?.template.title } : null}
                  label="Select a template"
                  placeholder="Select a template"
                  type="newsletter-html"
                  onChange={handleSelectTemplate}
                />
                <Select
                  label="Select a list of recipients"
                  onChange={handleSelectList}
                  value={campaign && campaign.list ? { value: campaign.list.id, label: campaign.list.title } : null}
                  options={lists?.map(l => ({ value: l, label: l.title + " -  " + l.count + " recipients" })) || []}
                />
              </div>
            </div>
            {campaign.fields && campaign.fields.length ? (
              <div className="settings">
                <h2>Template fields</h2>
                <div className="twoColumns fields"></div>
              </div>
            ) : null}
          </div>
          <div id="right">
            <div className="settings"></div>
          </div>
        </form>
      )}
      <div id="preview">
        <div className="header">
          <p>Email preview</p>
          {campaign.uri ? (
            <a target="tab" href={campaign.uri}>
              {" "}
              Open web version{" "}
            </a>
          ) : null}
        </div>
        <iframe title="Email preview" srcDoc={campaign.html} />
      </div>
    </div>
  );
}
