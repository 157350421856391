import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { MainLogoLetters, NoTextComponent } from "../components/global/icons";
import Intro from "../components/intro";
import { GET_MFA_SECRET, POST_ADD_MFA } from "../graphql/queries";
import { Store } from "../stores/stores";

export default function MfaQrCode() {
  const { loading: secretLoading, data: secretData } = useQuery(GET_MFA_SECRET);
  const [addMfa] = useMutation(POST_ADD_MFA);
  const [error, setError] = useState(null);
  const [token, setToken] = useState("");

  const onSubmit = async token => {
    setError(null);
    try {
      const results = await addMfa({ variables: { token } });
      Store.update(s => {
        s.session = results?.data?.addMfa;
      });
    } catch (error) {
      setError(error.toString());
    }
  };

  const handleChange = e => {
    const value = e.target.value;
    setToken(value);
    if (value.length === 6) {
      onSubmit(value);
    }
  };

  if (secretLoading) return <Intro />;
  return (
    <div id="mfaQrCodeContainer">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <NoTextComponent />
      </div>
      <img id="mfaQrCode" src={secretData.secretMfa} alt="" />
      Please scan the QR Code above with your authenticator app, and type the token below for verification.
      <form onSubmit={onSubmit} id="addMfaForm">
        <input
          type="text"
          autoFocus
          autoComplete="off"
          name="token"
          pattern="[0-9]{6}"
          placeholder="Enter 6 digits code"
          onChange={handleChange}
          value={token}
          required
        />
      </form>
      {error ? <p className="danger">{error}</p> : null}
    </div>
  );
}
