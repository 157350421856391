import React, { useContext } from "react";
import ReactSelect from "react-select";
import Creatable from "react-select/creatable";
import styled, { ThemeContext } from "styled-components";
import { Store } from "../../stores/stores";
import Async from "react-select/async";

import colors from "@common-ground-io/common-assets/assets/colors.json";
import { GET_TEMPLATES } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";

const layout = {
  light: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyLightest,
      bcgColorMultiValue: colors.greyLighter,
      fontColorInput: colors.greyDarker,
      fontColorMenuSelected: colors.greyDarker,
      fontColorMenuInactive: colors.grey,
      fontColorPlaceholder: colors.grey,
      indicatorColor: colors.greyDark
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.white,
      bcgColorMultiValue: colors.greyLighter,
      fontColorInput: colors.greyDarker,
      fontColorMenuSelected: colors.greyDarker,
      fontColorMenuInactive: colors.grey,
      fontColorPlaceholder: colors.grey,
      indicatorColor: colors.grey
    }
  },
  dark: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDarker,
      bcgColorMultiValue: colors.greyDark,
      fontColorInput: colors.greyLighter,
      fontColorMenuSelected: colors.greyLighter,
      fontColorMenuInactive: colors.grey,
      fontColorPlaceholder: colors.greyLight,
      indicatorColor: colors.grey
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDark,
      bcgColorMultiValue: colors.grey,
      fontColorInput: colors.greyLighter,
      fontColorMenuSelected: colors.greyLighter,
      fontColorMenuInactive: colors.grey,
      fontColorPlaceholder: colors.greyLight,
      indicatorColor: colors.grey
    }
  }
};

const computeStyles = ({ theme = "light", variant = "primary", label }) => {
  return {
    control: provided => {
      return {
        ...provided,
        height: "auto",
        minHeight: "35px",
        boxShadow: "none !important",
        borderColor: "transparent",
        borderRadius: 10,
        backgroundColor: layout[theme][variant].bcgColorInput,
        borderStyle: "none",
        color: layout[theme][variant].fontColorInput,
        marginTop: label ? "10px" : "0px",
        "&:hover": {
          borderColor: "transparent",
          color: layout[theme][variant].fontColorInput
        }
      };
    },
    clearIndicator: base => {
      // The cross to delete values
      return {
        ...base,
        color: layout[theme][variant].indicatorColor,
        "&:hover": {
          color: layout[theme][variant].indicatorColor
        }
      };
    },
    indicator: base => {
      // The vertical bar
      return { ...base };
    },
    indicatorContainer: base => {
      // The vertical bar
      return { ...base, height: "30px" };
    },
    indicatorSeparator: base => {
      // The vertical bar
      return { display: "none" };
    },
    dropdownIndicator: base => {
      // The arrow to open the dropdown
      return {
        ...base,
        color: layout[theme][variant].indicatorColor,
        "&:hover": {
          color: layout[theme][variant].indicatorColor
        }
      };
    },
    menu: base => ({
      ...base,
      zIndex: 2,
      color: "red",
      backgroundColor: layout[theme][variant].bcgColorInput,
      borderRadius: 10
    }),
    menuList: base => ({
      ...base,
      zIndex: 2,
      borderRadius: 10
    }),
    valueContainer: provided => ({
      ...provided,
      color: layout[theme][variant].indicatorColor,
      borderRadius: 10,
      height: "auto"
    }),
    singleValue: provided => ({
      // Value shows at rest
      ...provided,
      backgroundColor: layout[theme][variant].bcgColorInput,
      color: layout[theme][variant].fontColorInput,
      "&:hover": {
        cursor: "pointer"
      }
    }),
    multiValue: provided => ({
      // Multi Value shows at rest
      ...provided,
      backgroundColor: layout[theme][variant].bcgColorMultiValue
    }),
    multiValueLabel: provided => ({
      // Multi Value shows at rest
      ...provided,
      color: layout[theme][variant].fontColorInput
    }),
    multiValueRemove: provided => ({
      // Multi Value shows at rest
      ...provided,
      color: layout[theme][variant].fontColorInput,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: layout[theme][variant].indicatorColor,
        color: layout[theme][variant].fontColorInput
      }
    }),
    input: provided => ({
      // input value as you type in
      ...provided,
      margin: "0px",
      backgroundColor: "transparent",
      color: layout[theme][variant].fontColorInput
    }),
    option: (provided, state) => {
      // dropdown input value
      return {
        ...provided,
        borderColor: "transparent",
        color: state.isSelected
          ? layout[theme][variant].fontColorMenuSelected
          : layout[theme][variant].fontColorMenuInactive,
        backgroundColor: layout[theme][variant].bcgColorInput,
        "&:hover": {
          cursor: "pointer"
        }
      };
    }
  };
};

const computeVariant = (variant, layout) => (layout.light[variant] ? variant : "primary");
const path = (layout, props) => layout[props.theme.name || "light"][props.variant];

const Styledlabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(layout, props).fontColorLabel};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Select = props => {
  const variant = computeVariant(props.variant, layout);
  const { name: theme } = useContext(ThemeContext);

  return (
    <Container className="cg-common cg-select">
      {props.label && (
        <Styledlabel
          htmlFor={props.id || props.name || ""}
          className={`cg-common ${variant} ${props.className || ""}-label`}
          variant={variant}>
          {props.label}
        </Styledlabel>
      )}
      <ReactSelect
        {...props}
        className={`select ${props.className || ""}`}
        styles={computeStyles({ variant, theme, label: props.label })}
        isMulti={props.isMulti || false}
        classNamePrefix="select"
        isDisabled={props.readOnly || props.readonly}
      />
    </Container>
  );
};

const SelectCreatable = props => {
  const variant = computeVariant(props.variant, layout);
  const { name: theme } = useContext(ThemeContext);

  return (
    <Container className="cg-common cg-select cg-selectCreatable">
      {props.label && (
        <Styledlabel
          htmlFor={props.id || props.name || ""}
          className={`cg-common ${variant} ${props.className || ""}-label`}
          variant={variant}>
          {props.label}
        </Styledlabel>
      )}
      <Creatable
        {...props}
        className={props.className || "select"}
        classNamePrefix="select"
        styles={computeStyles({ variant, theme, label: props.label })}
        isMulti={props.isMulti || false}
        isClearable={props.isClearable === undefined || false}
      />
    </Container>
  );
};

const SelectAsync = props => {
  const variant = props.variant || "primary";
  const { name: theme } = useContext(ThemeContext);

  return (
    <Container className="cg-common cg-select cg-selectAsync">
      {props.label && (
        <Styledlabel
          htmlFor={props.id || props.name || ""}
          className={`cg-common ${variant} ${props.className || ""}-label`}
          variant={variant}>
          {props.label}
        </Styledlabel>
      )}
      <Async
        className={`select ${props.className || ""}`}
        styles={computeStyles({ variant, theme, label: props.label })}
        classNamePrefix="select"
        isDisabled={props.readonly}
        {...props}
      />
    </Container>
  );
};

const SelectTemplate = props => {
  const theme = Store.useState(c => c.theme);
  const query = { sortBy: "title", sortOrder: 1 };
  if (props.type) query.types = [props.type];
  else if (props.types) query.types = props.types;
  const [, { data, refetch }] = useLazyQuery(GET_TEMPLATES, { variables: query });

  const templates = data?.templates?.templates || [];

  const handleLoadTemplates = () => refetch();

  const handleChange = e => {
    props.onChange(e.value);
  };

  return (
    <Select
      {...props}
      theme={theme}
      onMenuOpen={handleLoadTemplates}
      options={templates?.map(t => ({ label: t.title, value: t })) || []}
      className={`select ${props.className || ""}`}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={handleChange}
      placeholder={props.placeholder}
      classNamePrefix="select"
      isDisabled={props.readonly}
      required={props.required}
    />
  );
};
export { Select, SelectAsync, SelectCreatable, SelectTemplate };
