import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import URI from "urijs";
import { Store } from "../stores/stores";
import Loader from "../components/global/loader";
import Button from "../components/styled/button";
import Input from "../components/styled/input";
import { GET_CAMPAIGNS, POST_CAMPAIGN_CREATE, POST_CAMPAIGN_DELETE } from "../graphql/queries/campaign";

export default function Campaigns() {
  const history = useHistory();
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);
  const { addNotification } = Store.useState(s => s);

  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const { data, refetch } = useQuery(GET_CAMPAIGNS, {
    fetchPolicy: "cache-and-network",
    variables: { page: parseInt(searchQuery.page) || 1 }
  });
  const [createCampaign] = useMutation(POST_CAMPAIGN_CREATE);
  const [deleteCampaign] = useMutation(POST_CAMPAIGN_DELETE);
  document.title = "Campaigns";

  const campaigns = data?.campaigns?.campaigns;
  const pagination = data?.campaigns?.pagination;

  const handleDeleteCampaign = async c => {
    deleteCampaign({ variables: { campaignRef: c._id } })
      .then(() => refetch())
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const handleCreateCampaign = async e => {
    e.preventDefault();
    if (isCreatingCampaign) return;
    try {
      setIsCreatingCampaign(true);
      const { data } = await createCampaign({ variables: { title: e.target.title.value } });
      refetch().then(() => {
        addNotification({ ok: 1, message: "Campaign created" });
        history.push(`/campaign/${data.campaign._id}`);
      });
    } catch (e) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsCreatingCampaign(false);
    }
  };

  if (!campaigns || !pagination) return <Loader withMargins />;

  return (
    <div id="campaigns">
      <section className="header">
        <h1>Campaigns</h1>
        <div id="newCampaign">
          <form onSubmit={handleCreateCampaign}>
            <div className="content">
              <Input
                disabled={isCreatingCampaign}
                autoFocus={true}
                defaultValue=""
                label="Enter a title for this new campaign"
                type="text"
                name="title"
                placeholder="Enter a title"
                autoComplete="off"
                required
              />
              <Button disabled={isCreatingCampaign} type="submit">
                {isCreatingCampaign ? <Loader /> : "Create campaign"}
              </Button>
            </div>
          </form>
        </div>
      </section>
      <section>
        <div id="campaignsTable">
          <div className="campaignTableEntry header">
            <p className="title">Title</p>
            <p className="created">Created</p>
            <p className="status">Status</p>
            <p className="status">CG News</p>
            <p className="preview">Preview</p>
            <p>Action</p>
          </div>
          {campaigns.map((c, index) => (
            <div key={c._id} className="campaignTableEntry">
              <Link to={`/campaign/${c._id}`} className="title">
                {c.title}
              </Link>
              <p className="created">{moment(c.created).format("ll")}</p>
              <p className="status">{c.status}</p>
              <p className="status">{c.publication.isCGNews ? "yes" : "no"}</p>
              <p>
                <a target="tab" href={c.uri}>
                  Web version
                </a>
              </p>
              {c.status === "draft" ? (
                <Button
                  variant="danger"
                  onClick={e =>
                    window.confirm("Are you sure you wish to delete this campaign? It cannot be recovered") &&
                    handleDeleteCampaign(c)
                  }
                  type="button">
                  Delete
                </Button>
              ) : (
                <Link to={`/campaign/${c._id}`} className="title">
                  <Button variant="primary">Details</Button>
                </Link>
              )}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
