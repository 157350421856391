import React, { useEffect, useState, useCallback } from "react";
import { Input, Select, TextArea } from "@common-ground-io/common-assets/react/";
import { useQuery, useMutation } from "@apollo/client";
import Loader from "../components/global/loader";
import {
  GET_TEMPLATE,
  POST_UPDATE_TEMPLATE,
  POST_TEMPLATE_SEND_TEST_EMAIL,
  POST_TEMPLATE_VERSION_BUMP
} from "../graphql/queries";
import clone from "clone";
import { Store } from "../stores/stores";

import axios from "axios";
import { marked } from "marked";
import { SandpackProvider, SandpackLayout, SandpackCodeEditor, useSandpack } from "@codesandbox/sandpack-react";
import Button from "../components/styled/button";
import ConfigSelector from "../components/configSelector";
const isProduction = process.env.REACT_APP_BUILD_ENV === "production";
const apiEndpoint = isProduction ? "https://api.common-ground.io" : "https://api-staging.common-ground.media";

const TemplateEditor = ({ template: templateFromProps }) => {
  const [bumpVersion] = useMutation(POST_TEMPLATE_VERSION_BUMP);
  const { addNotification } = Store.useState(s => s);
  const [updateTemplate, { loading: saving }] = useMutation(POST_UPDATE_TEMPLATE);
  const [dirty, setDirty] = useState(false);
  const [template, setTemplate] = useState(clone(templateFromProps));

  const [preview, setPreview] = useState("");
  const [isComputingPreview, setIsComputingPreview] = useState(false);
  const [timerHandle, setTimerHandle] = useState(undefined);
  const [config, setConfig] = useState(null);
  const [sendTestEmail, { loading: sendingTestEmail }] = useMutation(POST_TEMPLATE_SEND_TEST_EMAIL);

  const { sandpack } = useSandpack();
  const { files, activeFile } = sandpack;

  const code = files[activeFile].code;

  const handleSelectChange = selectedOption => {
    setTemplate({ ...template, type: selectedOption.value });
  };

  useEffect(() => {
    if (code !== template.data.content) {
      setDirty(true);
    }
    if (!isComputingPreview) {
      if (timerHandle !== undefined) {
        clearTimeout(timerHandle);
        const id = setTimeout(() => computePreview(code), 500);
        setTimerHandle(id);
      } else if (!timerHandle) setTimerHandle(1);
    }
  }, [code]);

  const handleNewVersion = async e => {
    e.preventDefault();
    try {
      const { data } = await bumpVersion({ variables: { notes: e.target.notes.value, templateRef: template._id } });
      setTemplate(clone(data.templateVersionBump));
    } catch (error) {
      addNotification({ ok: 0, message: error.message });
    }
  };

  const handleUpdateTemplate = async e => {
    e.preventDefault();

    const content = code;
    try {
      const dataToSend = clone(template);
      const { data } = await updateTemplate({
        variables: {
          ref: template._id,
          templateUpdateInput: {
            title: dataToSend.title,
            type: dataToSend.type,
            content
          }
        }
      });
      setTemplate(clone(data.templateUpdate));
      setDirty(false);
      addNotification({ ok: 1, message: "Template updated" });
    } catch (error) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  let templateTypes = [
    { value: "newsletter-html", label: "Newsletter - html" },
    { value: "sticker-html", label: "Sticker - html" },
    { value: "order-invoice-html-pdf", label: "Order Invoice- html/pdf" },
    { value: "credit-invoice-html-pdf", label: "Credit Invoice- html/pdf" },
    { value: "order-receipt-html", label: "Order email receipt - html" },
    { value: "invoice-receipt-html", label: "Invoice email receipt - html" },
    { value: "message-receipt-html", label: "Message email receipt - html" },
    { value: "shipping-receipt-html", label: "Shipping email receipt - html" },
    { value: "backinstock-receipt-html", label: "Back in stock receipt - html" },
    { value: "message", label: "Order message - text" },
    { value: "comment", label: "Listing comment - text" },
    { value: "account-creation-receipt-html", label: "Account creation receipt - html" },
    { value: "cg-invoice-html-pdf", label: "CG Invoice - html/pdf" },
    { value: "cg-invoice-receipt-html", label: "CG Invoice receipt - html" },
    { value: "cg-invoice-reminder-html", label: "CG Invoice reminder - html" },
    { value: "cg-trial-end-notice-html", label: "CG Trial end notice - html" },
    { value: "cg-account-suspension-notice-html", label: "CG Account suspension notice - html" },
    { value: "html", label: "Generic - html" },
    { value: "terms-markdown", label: "Generic - Markdown" }
  ];

  const reloadData = useCallback(async () => {
    if (!config) return;
    try {
      await axios.post(
        `${apiEndpoint}/template/${template._id}/dataset`,
        {},
        {
          headers: {
            "commonground-jwt": localStorage.getItem("jwt"),
            "commonground-origin": config.domain
          }
        }
      );
    } catch (e) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
  }, [config, addNotification, template]);

  const computePreview = useCallback(
    async content => {
      if (!template._id) return;
      setIsComputingPreview(true);
      try {
        const { data: renderedData } = await axios.post(
          `${apiEndpoint}/template/${template._id}/render`,
          { content },
          {
            headers: {
              "commonground-jwt": localStorage.getItem("jwt"),
              "commonground-origin": config?.domain || "demo.common-ground.io"
            }
          }
        );
        setPreview(renderedData.render);
      } catch (e) {
        setPreview(`<!DOCTYPE html><html><head><body><p>${e.response?.data || e.toString()}</p></body></head></html>`);
      } finally {
        setIsComputingPreview(false);
      }
    },
    [template._id, config]
  );

  const handleSendTestEmail = async () => {
    try {
      const { data } = await sendTestEmail({ variables: { configRef: config._id, templateRef: template._id } });
      addNotification({ ok: 1, message: data.templateSendTestEmail });
    } catch (error) {
      addNotification({ ok: 0, message: error.message });
    }
  };

  const handleDatasetGenerate = async () => {
    setIsComputingPreview(true);
    await reloadData();
    await computePreview(template.data.content);
    setIsComputingPreview(false);
  };

  let codeType = "text";
  if (template.type.includes("html")) codeType = "pug";
  if (template.type.includes("markdown")) codeType = "markdown";

  const html = codeType === "markdown" && marked.parse(template.data.content);

  const handleChangeConfig = config => {
    console.log(config);
    localStorage.setItem("template-configRef", config._id);
    setConfig(config);
  };

  return (
    <div id="template">
      <form onSubmit={handleUpdateTemplate}>
        <section className="header">
          <div className="left">
            <Input
              label="Title"
              type="text"
              name="title"
              onChange={e => setTemplate({ ...template, title: e.target.value })}
              value={template.title || ""}
            />
            <Select
              label="Type"
              onChange={handleSelectChange}
              options={templateTypes}
              value={templateTypes.find(t => t.value === template.type)}
            />
          </div>
          <Button type="submit">{saving ? <Loader /> : <>{dirty ? "*" : ""}Save template</>}</Button>
        </section>
        <div className="editor"></div>
      </form>
      <div id="codeEditor">
        <SandpackLayout>
          <SandpackCodeEditor style={{ height: "80vh" }} showLineNumbers={true} showInlineErrors />
        </SandpackLayout>
        <div id="codePreview">
          <div className="header">
            <h3>Live preview</h3>
            <ConfigSelector onChangeConfig={handleChangeConfig} />
            <Button
              variant="secondary"
              disabled={!!sendingTestEmail}
              type="button"
              onClick={e => handleSendTestEmail()}>
              {sendingTestEmail ? "Sending..." : "Send test email"}
            </Button>
            {codeType === "pug" ? (
              <Button variant="secondary" disabled={false} type="button" onClick={e => handleDatasetGenerate()}>
                {isComputingPreview ? "Processing..." : "Shuffle data"}
              </Button>
            ) : null}
          </div>
          {codeType === "markdown" ? (
            <div title="Preview" className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
          ) : (
            <iframe title="Preview" srcDoc={preview} />
          )}
        </div>
      </div>
      <div className="versions">
        <h2>Versions</h2>
        {template.versions.map(v => (
          <div className="version" key={v.version}>
            <p>
              {v.version}
              <br />
              {v.notes}
            </p>
          </div>
        ))}
      </div>
      <form onSubmit={handleNewVersion}>
        <TextArea name="notes" defaultValue="" required placeholder="Release notes..." />
        <Button type="submit">Publish new version</Button>
      </form>
    </div>
  );
};

const Template = props => {
  const { data, loading, error } = useQuery(GET_TEMPLATE, { variables: { ref: props.match.params.ref } });
  const { addNotification } = Store.useState(s => s);
  const template = data?.template;

  if (error) addNotification({ ok: 0, message: error.message });

  const setupReact = useCallback(() => {
    const filesObject = {};

    const files = [{ path: "/index.scss", content: template?.data?.content || "", active: true }];
    for (const file of files) {
      filesObject[file.path] = {
        code: file.content || "",
        hidden: false,
        readOnly: false,
        active: true
      };
    }
    return {
      customSetup: {
        entry: "/index.scss",
        environment: "react",
        dependencies: {}
      },
      files: filesObject
    };
  }, [template?.data?.content]);

  if (loading || !template) return <Loader />;

  return (
    <SandpackProvider theme="dark" {...setupReact()}>
      <TemplateEditor template={template} />;
    </SandpackProvider>
  );
};

export default Template;
