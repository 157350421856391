import React from "react";
import { Link, useLocation } from "react-router-dom";
import URI from "urijs";

export default function Pagination ({ pagination }) {
  const location = useLocation();

  if (!pagination) return null;
  const { page = 1, pages = 1, count = 0 } = pagination || {};
  const currentUri = new URI(location.pathname + location.search);
  const nextUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: page + 1 });
  const prevUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: page - 1 });

  return (
    <div className="pagination">
      <p className="page">
        Page {page} of {pages || 1} | {count}
        {" items"}
      </p>
      <nav>
        <ul>
          {page > 1 && (
            <li>
              <Link to={prevUri.toString()} className="previous">
                {"<"} <span className="paginationWords">Previous</span>
              </Link>
            </li>
          )}
          <li>
            Page {page} of {pages || 1}
          </li>
          {page < pages && (
            <li>
              <Link to={nextUri.toString()} className="next">
                <span className="paginationWords">Next</span> {">"}
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}
