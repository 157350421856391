import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../components/global/loader";
import {
  GET_CONTACTS,
  GET_LISTS,
  POST_CONTACT,
  POST_CONTACT_EDIT,
  POST_CONTACT_IMPORT,
  POST_DELETE_CONTACT,
  POST_LIST_ADD,
  POST_LIST_CREATE,
  POST_LIST_DELETE,
  POST_LIST_REMOVE
} from "../graphql/queries";
import Button from "../components/styled/button";
import { Store } from "../stores/stores";
import { Input, Select, Zone } from "@common-ground-io/common-assets/react";
import { NetworkStatus } from "@apollo/client";
import URI from "urijs";
import moment from "moment";

export default function Contacts() {
  const history = useHistory();
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);
  const term = searchQuery.term;
  const { data, loading, refetch, networkStatus } = useQuery(GET_CONTACTS, {
    variables: { term: searchQuery.term, listRef: searchQuery.listRef },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true
  });
  const [addUser] = useMutation(POST_CONTACT);
  const [importFromCG, { loading: loadingImport }] = useMutation(POST_CONTACT_IMPORT);
  const { addNotification } = Store.useState(s => s);

  const [newListName, setNewListName] = useState("");

  const { data: listData, refetch: refetchLists } = useQuery(GET_LISTS);
  const [addToList, { loading: addingToList }] = useMutation(POST_LIST_ADD);
  const [removeFromList, { loading: removingFromList }] = useMutation(POST_LIST_REMOVE);
  const [createList, { loading: creatingList }] = useMutation(POST_LIST_CREATE);
  const [deleteList, { loading: deletingList }] = useMutation(POST_LIST_DELETE);

  const lists = listData?.lists.lists;

  const handleAddUser = form => {
    form.preventDefault();

    addUser({
      variables: {
        contactEditInput: {
          firstName: form.target.firstName.value,
          lastName: form.target.lastName.value,
          organisation: form.target.organisation.value,
          email: form.target.email.value
        }
      }
    })
      .then(() => {
        addNotification({ ok: 1, message: "Contact added" });
        refetch();
      })
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const handleImportFromCG = () => {
    importFromCG().then(() => {
      refetch();
      refetchLists();
      addNotification({ ok: 1, message: "Imported contacts from CG" });
    });
  };

  const handleSubmitSearch = e => {
    e.preventDefault();
    const newUri = new URI(location.pathname);
    newUri.setSearch({ term: e.target.term.value });
    history.push(newUri.resource());
  };

  const handleAddToList = async (user, listRef) => {
    if (addingToList) return;
    try {
      await addToList({ variables: { listRef, contactRef: user._id } });
      refetchLists();
      refetch();
    } catch (e) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleRemoveFromList = async (user, listRef) => {
    if (removingFromList) return;
    try {
      await removeFromList({ variables: { listRef, contactRef: user._id } });
      refetchLists();
      refetch();
    } catch (e) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleCreateList = async e => {
    if (creatingList) return;
    e.preventDefault();
    try {
      await createList({ variables: { title: newListName } });
      refetchLists();
    } catch (e) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleListSelect = async (option, event, name) => {
    const newUri = new URI(location.pathname);
    newUri.removeSearch("page");
    if (event.action === "clear") {
      newUri.removeSearch(["listRef", "term"]);
      localStorage.removeItem("users-list-filter");
    } else if (event.action === "select-option") {
      newUri.setSearch({ listRef: option.value._id });
    }
    history.push(newUri.resource());
  };

  const handleClearTerm = async (option, event, name) => {
    const newUri = new URI(location.pathname);
    newUri.removeSearch("term");
    history.push(newUri.resource());
  };

  const handleDeleteList = async list => {
    if (deletingList) return;
    try {
      await deleteList({ variables: { listRef: list._id } });
      refetchLists();
    } catch (e) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  if (!data) return <Loader />;
  const selectedList = lists && lists.find(l => l._id === searchQuery.listRef);

  console.log(term);

  return (
    <div id="contacts">
      <section className="header">
        <div className="left">
          <h1>Contacts</h1>
          <form onSubmit={handleSubmitSearch}>
            {term ? (
              <Button variant="secondary" type="button" onClick={handleClearTerm}>
                Clear search term: {term}
              </Button>
            ) : (
              <Input
                name="term"
                placeholder="Search through contacts"
                defaultValue=""
                autoComplete="off"
                type="text"
                className="status"
              />
            )}
          </form>
          {loading || networkStatus === NetworkStatus.refetch ? <Loader /> : null}
        </div>
        <div>
          <Button onClick={handleImportFromCG} type="button">
            {loadingImport ? <Loader /> : "Refresh active admins list"}
          </Button>
        </div>
      </section>
      <Zone id="lists">
        <div id="listFilterAndNew">
          <Select
            label="Filter by list"
            variant="overZone"
            onChange={handleListSelect}
            value={
              selectedList
                ? {
                    label: `${selectedList.title} (${selectedList.count})`,
                    value: selectedList._id
                  }
                : null
            }
            options={(lists || []).map(l => ({ value: l, label: `${l.title} (${l.count})` }))}
            name="category"
          />
          {searchQuery.listRef ? (
            <Button variant="danger" type="button" onClick={e => history.push("/contacts")}>
              Clear list filter
            </Button>
          ) : null}
          <form id="newListForm" onSubmit={handleCreateList}>
            <Input
              variant="overZone"
              label="Create new list"
              value={newListName}
              placeholder="The list title"
              autoComplete="off"
              required
              onChange={e => setNewListName(e.target.value)}
              name="title"
            />
            <Button type="submit" variant="secondary">
              Create List
            </Button>
          </form>
        </div>
        <div id="listsView">
          {lists ? (
            lists.map(l => (
              <Zone inverted className="list" key={l.id}>
                <div className="listHeader">
                  <h3>{l.title}</h3>
                </div>
                <p>
                  {l.count} contacts
                  <br />
                  Created {moment(l.created).fromNow()}
                </p>
                <Button
                  variant="danger"
                  onClick={e => window.confirm("Are you sure you wish to delete this list?") && handleDeleteList(l)}>
                  Delete list
                </Button>
              </Zone>
            ))
          ) : (
            <p>No list were found</p>
          )}
        </div>
      </Zone>
      <form onSubmit={handleAddUser} style={{ marginBottom: "20px" }}>
        <input name="email" type="email" required placeholder="Email..." />
        <input name="firstName" type="text" required placeholder="First name..." />
        <input name="lastName" type="text" required placeholder="Last name..." />
        <input name="organisation" type="text" placeholder="Organisation..." />
        <Button type="submit" variant="secondary" disabled={loading}>
          Create contact
        </Button>
      </form>
      <p>{data.contacts.contacts.length} contacts </p>
      <div className="content">
        {data.contacts.contacts.map(d => (
          <Contact
            contact={d}
            key={d._id}
            refetch={refetch}
            addNotification={addNotification}
            lists={lists || []}
            handleAddToList={handleAddToList}
            handleRemoveFromList={handleRemoveFromList}
          />
        ))}
      </div>
    </div>
  );
}

const Contact = ({ contact, refetch, addNotification, lists, handleAddToList, handleRemoveFromList }) => {
  const [deleteUser] = useMutation(POST_DELETE_CONTACT);
  const [isEditing, setIsEditing] = useState(false);
  const [editUser, { loading }] = useMutation(POST_CONTACT_EDIT);

  const handleDeleteUser = async ref => {
    deleteUser({ variables: { contactRef: ref } }).then(e => refetch());
  };

  const handleEditUser = form => {
    form.preventDefault();

    editUser({
      variables: {
        contactRef: contact._id,
        contactEditInput: {
          firstName: form.target.firstName.value,
          lastName: form.target.lastName.value,
          organisation: form.target.organisation.value,
          email: form.target.email.value
        }
      }
    })
      .then(({ data }) => {
        setIsEditing(false);
        addNotification({ ok: 1, message: "Contact updated" });
        refetch();
      })
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  if (isEditing)
    return (
      <div>
        <form onSubmit={handleEditUser} style={{ marginBottom: "20px" }}>
          <input
            name="email"
            autoComplete="off"
            defaultValue={contact.email}
            type="email"
            required
            placeholder="Email..."
          />
          <input
            name="firstName"
            autoComplete="off"
            defaultValue={contact.firstName}
            type="text"
            required
            placeholder="First name..."
          />
          <input
            name="lastName"
            autoComplete="off"
            defaultValue={contact.lastName}
            type="text"
            required
            placeholder="Last name..."
          />
          <input
            name="organisation"
            defaultValue={contact.organisation}
            type="text"
            required
            placeholder="Organisation..."
          />
          <Button type="submit" disabled={loading}>
            Submit
          </Button>
        </form>
        <Button type="submit" variant="secondary" onClick={e => setIsEditing(false)}>
          Cancel
        </Button>
      </div>
    );
  else
    return (
      <div className="editContact">
        <div className="entry">
          <h2>
            {contact.firstName} {contact.lastName}
          </h2>
          <p>{contact.organisation}</p>
          <p>{contact.email}</p>
          <div className="lists">
            {lists ? (
              <Lists
                lists={lists}
                addToList={handleAddToList}
                removeFromList={handleRemoveFromList}
                contact={contact}
              />
            ) : null}
          </div>
          <div className="buttons">
            <Button type="button" variant="secondary" onClick={e => setIsEditing(true)}>
              Edit
            </Button>

            <Button
              variant="danger"
              type="button"
              onClick={e => window.confirm("Are you sure?") && handleDeleteUser(contact._id)}>
              Delete
            </Button>
          </div>
        </div>
      </div>
    );
};

const Lists = ({ addToList, removeFromList, contact, lists }) => {
  const handleDropdownChange = async (values, event, name) => {
    if (event.action === "select-option") addToList(contact, event.option.value);
    else if (event.action === "clear") {
      for (const list of contact.lists) {
        await removeFromList(contact, list.ref);
      }
    } else if (event.action === "remove-value") await removeFromList(contact, event.removedValue.value);
  };

  const entries = [];
  const options = [];
  lists.forEach(l => {
    const isInList = contact.lists.find(ul => ul.ref === l._id);
    const isOptedOut = isInList && isInList.optout.status;
    if (isInList && !isOptedOut) {
      entries.push({ label: l.title, value: l._id, disabled: isOptedOut });
    }
    options.push({
      label: `${l.title} ${isOptedOut ? ` - Opted out with reason: ${isInList.optout.reason}` : ""}`,
      value: l._id,
      disabled: isOptedOut
    });
  });

  return (
    <Select
      placeholder="Add to list..."
      variant="overZone"
      isMulti={true}
      label="Lists"
      isClearable={false}
      options={options}
      value={entries}
      onChange={(a, b) => handleDropdownChange(a, b)}
    />
  );
};
