import { gql } from "@apollo/client";

const PAGE_INFO = `
  endCursor
  hasNextPage
`;

const PAGINATION = `
  count
  page
  pages
  perPage
`;

const USER = `
  _id
  firstName
  lastName
  name
  email
  mfa {
    active
  }
`;

const CONTACT = `
  _id
  firstName
  lastName
  name
  email
  telephone
  organisation
  lists {
    ref
    optout {
      status
      date
      reason
    }
  }
`;

const SESSION = `
  user {
    ${USER}
  }
  jwt
  expires
  mfa
`;

const PLAN = `
  seats
  expires
  title
  recurrence
  description
  nextPaymentDate
  price {
    value
  }
  trial {
    days
    available
    active
  }
`;

const TEMPLATE_FIELDS = `
  _id
  created
  createdBy {
    name
  }
  data {
    content
    fields { 
      type
      name
      value
      description
      required
    }
    model
    dataSet
  }
  version
  versions {
    version
    notes
  }
  title
  type
  global
  engine
`;

const CONFIG = `
  _id
  id
  uri
  created
  domain
  shopName
  currencySymbol
  currencySymbolPosition
  status {
    active
    reason
  }
  admins {
    role
    added {
      date
    }
    admin {
      firstName
      lastName
      email
    }
  }
  sites {
    isPrimary
    domain
  }
  modules
  stack {
    id
    name
    status
  }
  eshop {
    theme {
      generation
    }
    checkout {
      version
    }
  }
  stripe {
    customerId
  }
  plan {
    ${PLAN}
  }
  taxes {
    country {
      name
      code
    }
  }
  community {
    feed {
      enabled
    }
  }
  storage {
    database {
      name
    }
    backups {
      date
    }
  }
`;

const FONT_FIELDS = `
  _id
  title
  creator
  type
  family
  created
  tags
  fontFace
  variations {
    _id
    title
    files {
      uri
      format
      fileName
    }
    style
    weight
  }
`;

const THEME_BROWSER_ENTRY = `
  _id
  themeRef
  configRef
  tags
  title
  credits
  published
  previewUrl
  bannerImages {
    _id
    name
    uri
  }
  description
  createdAt
  updatedAt
`;

const INVOICE_TOTAL = `
  currency:
  value
  currencySymbol
`;
const INVOICE_PAID = `
  status
  date
  processing
  method
  charge
`;
const INVOICE_ITEM_PRICE = `
  value
  currency
  currencySymbol
`;
const INVOICE_ITEM = `
  price {
    ${INVOICE_ITEM_PRICE}
  }
  title
`;

const INVOICE = `
  _id
  title
  id
  type
  created
  note
  shopName
  pdf
  path
  items {
    ${INVOICE_ITEM}
  }
  total {
    ${INVOICE_TOTAL}
  }
  paid {
    ${INVOICE_PAID}
  }
  config {
    _id
    domain
    shopName
  }
  totalInConfigCurrency {
    currency
    value
    currencySymbol
  }
`;

const LIST_FIELDS = `
  _id
  id
  title
  created
  description
  count
`;

export const GET_THEMES_BY_CONFIG_ID = gql(`
  query($configRef: ID!) {
    themesByConfigId(configRef: $configRef) {
      _id
      title
    } 
}`);

export const GET_THEME_BROWSER_ENTRIES = gql(`
  query {
    themeBrowserEntries {
      ${THEME_BROWSER_ENTRY}
    } 
}`);

export const POST_THEME_BROWSER_ENTRY = gql(`
  mutation($configRef: ID!, $themeRef: ID!, $title: String!, $tags: [String], $credits: String, $bannerImages: [Upload], $description: String!, $published: Boolean!) {
    themeBrowserEntryAdd(configRef: $configRef, themeRef: $themeRef, title: $title, tags: $tags, credits: $credits, bannerImages: $bannerImages, description: $description, published: $published) {
      ${THEME_BROWSER_ENTRY}
    }
}`);

export const POST_THEME_BROWSER_ENTRY_UPDATE = gql(`
  mutation($themeEntryRef: ID!, $themeRef: ID, $createdAt: Date, $archived: Boolean, $title: String!, $tags: [String], $credits: String, $bannerImages: [Upload], $description: String!, $published: Boolean!) {
    themeBrowserEntryUpdate(themeEntryRef: $themeEntryRef, themeRef: $themeRef, createdAt: $createdAt, archived: $archived, title: $title, tags: $tags, credits: $credits, bannerImages: $bannerImages, description: $description, published: $published) {
      ${THEME_BROWSER_ENTRY}
    }
}`);

export const POST_THEME_BROWSER_ENTRY_ARCHIVE = gql(`
  mutation($themeEntryRef: ID!) {
    themeBrowserEntryArchive(themeEntryRef: $themeEntryRef)
}`);

export const GET_SESSION = gql(`
  query {
    session {
      ${SESSION}
    }
}`);

export const POST_LOGIN = gql(`
  mutation($password: String!, $email: String!) {
    login(password: $password, email: $email) {
      ${SESSION}
    }
}`);

export const POST_LOGOUT = gql(`
  mutation {
    logout {
      ok
    }
}`);

export const GET_CONFIGS = gql(`
  query($status: String, $search: String){
    configsConnection(sortOrder: -1, status: $status, search: $search) {
      configs {
        ${CONFIG}
      }
    }
}`);

export const GET_CONFIGS_FOR_MAPS = gql(`
  query($status: String, $search: String){
    configsConnection(sortOrder: -1, status: $status, search: $search) {
      configs {
        modules
      }
    }
}`);

export const GET_INVOICES = gql(`
  query($sortOrder: Int, $sortBy: String, $page: Int, $perPage: Int, $limit: Int, $configRef: ID) {
    invoices(sortOrder: $sortOrder, sortBy: $sortBy, page: $page, perPage: $perPage, limit: $limit, configRef: $configRef) {
      invoices {
        ${INVOICE}
      }
      pagination {
        ${PAGINATION}
      }
    }
}`);

export const GET_ORDERS = gql(`
  query($sortOrder: Int, $sortBy: String, $page: Int, $perPage: Int, $limit: Int, $configRef: ID) {
    orders(sortOrder: $sortOrder, sortBy: $sortBy, page: $page, perPage: $perPage, limit: $limit, configRef: $configRef) {
      orders {
        configRef
        created
        origin
        id
        totals {
          grand
        }
      }
      pagination {
        ${PAGINATION}
      }
    }
}`);

export const GET_DASHBOARD = gql(`
  query($configRef: ID, $period: Int) {
    dashboard(configRef: $configRef, period: $period){
      graphs
      statistics
    }
}`);

export const POST_CONFIG_PLAN = gql(`
  mutation($configRef: ID!, $plan: JSON, $sendNotification: Boolean) {
    configUpdatePlan(plan: $plan, configRef: $configRef, sendNotification: $sendNotification) {
      ${CONFIG}
    }
}`);

export const POST_ADD_MFA = gql(`
  mutation($token: String!) {
    addMfa(token: $token) {
      ${SESSION}
    }
  }
`);

export const POST_MFA_AUTHENTICATE = gql(`
  mutation($token: String!) {
    authenticateMfa(token: $token) {
      ${SESSION}
    }
  }
`);

export const GET_MFA_SECRET = gql(`
  query {
    secretMfa
  }
`);

export const POST_REMOVE_MFA = gql(`
  mutation {
    removeMfa {
      ${SESSION}
    }
  }
`);

export const GET_PLANS = gql(`
  query {
    plans {
      totalCount
      edges {
        cursor
        node {
          ${PLAN}
        }
      }
      pageInfo {
        ${PAGE_INFO}
      }
    }
  }
`);

export const GET_DICTIONARIES = gql(`
  query {
    dictionaries {
      dictionaries {
        _id
        title
        languages {
          locale
        }
        key
        bucket
      }
    }
  }
`);

export const GET_STYLESHEETS = gql(`
  query {
    stylesheets {
      stylesheets {
        id
        title
        content
        uri
      }
    }
  }
`);

export const GET_USERS = gql(`
  query {
    users {
      users {
        ${USER}
      }
    }
  }
`);

export const GET_CONTACTS = gql(`
  query($term: String, $listRef: String) {
    contacts(term: $term, listRef: $listRef) {
      contacts {
        ${CONTACT}
      }
      pagination {
        pages
        page
      }
    }
  }
`);

export const POST_USER = gql(`
  mutation($firstName: String! $lastName: String! $email: String!) {
    usersAdd(firstName: $firstName lastName: $lastName email: $email) {
      users {
        ${USER}
      }
      user {
        ${USER}
      }
      password
    }
  }
`);

export const POST_CONTACT = gql(`
  mutation($contactEditInput: ContactEditInput!) {
    contactAdd(contactEditInput: $contactEditInput) {
      ${CONTACT}
    }
  }
`);

export const POST_CONTACT_IMPORT = gql(`
  mutation{
    contactImport
  }
`);

export const POST_CONTACT_EDIT = gql(`
  mutation($contactRef: ID!, $contactEditInput: ContactEditInput!) {
    contactEdit(contactRef: $contactRef, contactEditInput: $contactEditInput) {
      ${CONTACT}
    }
  }
`);

export const POST_DELETE_USER = gql(`
  mutation($userRef: ID!) {
    usersDelete(userRef: $userRef) {
      users {
        ${USER}
      }
    }
  }
`);

export const POST_DELETE_CONTACT = gql(`
  mutation($contactRef: ID!) {
    contactDelete(contactRef: $contactRef)
  }
`);

export const GET_STYLESHEET = gql(`
  query($id: ID!) {
    stylesheet(id: $id) {
      id
      title
      content
      uri
    }
  }
`);

const DICTIONARY_FIELDS = `
  _id
  title
  languages {
    locale
    keys
  }
  key
  bucket
  keyCount
  targets
`;

export const GET_DICTIONARY = gql(`
  query($dictionaryReference: ID!) {
    dictionary(dictionaryReference: $dictionaryReference) {
      ${DICTIONARY_FIELDS}
    }
  }
`);

export const GET_TEMPLATES = gql(`
  query {
    templates {
      templates {
        _id
        title
        type
        created
        version
        createdBy {
          name
        }
      }
    }
  }
`);

export const GET_FONTS = gql(`
  query {
    fonts {
      count
      edges {
        ${FONT_FIELDS}
      }
    }
  }
`);

export const POST_FONT_CREATE = gql(`
   mutation($title: String!) {
    fontCreate(title: $title) {
      ${FONT_FIELDS}
    }
  }
`);

export const POST_FONT_UPDATE = gql(`
   mutation($ref: ID!, $title: String!, $family: String!, $creator: String!, $type: String!, $tags: [String]!) {
    fontUpdate(ref: $ref, title: $title, family: $family, creator: $creator, type: $type, tags: $tags) {
      ${FONT_FIELDS}
    }
  }
`);

export const POST_FONT_ADD_VARIATION = gql(`
   mutation($ref: ID!, $title: String!) {
    fontAddVariation(ref: $ref, title: $title) {
      ${FONT_FIELDS}
    }
  }
`);

export const POST_FONT_UPDATE_VARIATION = gql(`
   mutation($ref: ID!, $variationRef: ID!, $title: String!, $style: String!, $weight: String!) {
    fontUpdateVariation(ref: $ref, variationRef: $variationRef, title: $title, style: $style, weight: $weight) {
      ${FONT_FIELDS}
    }
  }
`);

export const POST_FONT_UPLOAD_VARIATION_FILES = gql(`
   mutation($ref: ID!, $variationRef: ID!, $files: [Upload]!) {
    fontUploadVariationFiles(ref: $ref, variationRef: $variationRef, files: $files) {
      ${FONT_FIELDS}
    }
  }
`);

export const POST_FONT_DELETE_VARIATION = gql(`
   mutation($ref: ID!, $variationRef: ID!) {
    fontDeleteVariation(ref: $ref, variationRef: $variationRef) {
      ${FONT_FIELDS}
    }
  }
`);

export const POST_UPDATE_TEMPLATE = gql(`
   mutation($ref: ID!, $templateUpdateInput: TemplateUpdateInput!) {
    templateUpdate(ref: $ref, templateUpdateInput: $templateUpdateInput) {
      ${TEMPLATE_FIELDS}
    }
  }
`);

export const POST_CREATE_TEMPLATE = gql(`
   mutation($title: String!) {
    templateCreate(title: $title) {
      ${TEMPLATE_FIELDS}
    }
  }
`);

export const POST_TEMPLATE_VERSION_BUMP = gql(`
   mutation($templateRef: ID!, $notes: String!) {
    templateVersionBump(templateRef: $templateRef, notes: $notes) {
      ${TEMPLATE_FIELDS}
    }
  }
`);

export const POST_TEMPLATE_SEND_TEST_EMAIL = gql(`
   mutation($templateRef: ID!, $configRef: ID!) {
    templateSendTestEmail(templateRef: $templateRef, configRef: $configRef)
  }
`);

export const GET_TEMPLATE = gql(`
  query($ref: ID!) {
    template(ref: $ref) {
      ${TEMPLATE_FIELDS}
    }
  }
`);

export const POST_DICTIONARY_UPDATE = gql(`
  mutation($dictionaryReference: ID!, $dictionaryData: JSON!) {
    dictionaryUpdate(dictionaryReference: $dictionaryReference, dictionaryData: $dictionaryData) {
      ${DICTIONARY_FIELDS}
    }
  }
`);

export const POST_STYLESHEET_UPDATE = gql(`
  mutation($id: ID!, $content: String!) {
    stylesheetUpdate(id: $id, content: $content) {
      id
      title
      content
    }
  }
`);

export const POST_CREATE_PASSWORD_TOKEN = gql(`
  mutation($email: String!) {
    createPasswordToken(email: $email) {
      message
    }
  }
`);


export const POST_CONFIG_SITES = gql(`
  mutation($configRef: String!, $sites: JSON!) {
    configUpdateSites(configRef: $configRef, sites: $sites) {
      ${CONFIG}
    }
  }
`);

export const POST_CONFIG_STATUS = gql(`
  mutation($configRef: String!, $state: Boolean, $reason: String) {
    configUpdateStatus(configRef: $configRef, state: $state, reason: $reason) {
      ${CONFIG}
    }
  }
`);

export const POST_CONFIG_DELETE = gql(`
  mutation($configRef: String!, $token: String!) {
    configDelete(configRef: $configRef, token: $token)
  }
`);

export const POST_CONFIG_FEED_STATUS = gql(`
  mutation($configRef: String!, $status: Boolean!) {
    configFeedStatus(configRef: $configRef, status: $status) {
      ${CONFIG}
    }
  }
`);

export const POST_INVOICE = gql(`
  mutation (
    $configRef: ID!
    $title: String,
    $method: String
    $isPaid: Boolean
    $date: Date
    $currency: String
    $currencySymbol: String
    $items: [InvoiceItemInput]) {
    invoiceCreate(
      configRef: $configRef
      title: $title
      method: $method
      isPaid: $isPaid
      date: $date
      currency: $currency
      currencySymbol: $currencySymbol
      items: $items
      ) {
      ${INVOICE}
    }
  }
`);

export const POST_INVOICE_UPLOAD = gql(`
  mutation ($invoiceRef: ID!, $file: Upload!) {
    invoiceUpload(invoiceRef: $invoiceRef, file: $file) {
      ${INVOICE}
    }
  }
`);

export const POST_FONT_UPLOAD = gql(`
  mutation ($id: String!, $file: Upload!) {
    fontUpload(id: $id, file: $file)
  }
`);

export const POST_INVOICE_GENERATE = gql(`
  mutation ($invoiceRef: ID!) {
    invoiceGeneratePdf(invoiceRef: $invoiceRef) {
      ${INVOICE}
    }
  }
`);

export const POST_INVOICE_UPLOAD_PENNYLANE = gql(`
  mutation ($invoiceRef: ID!) {
    invoiceUploadPennylane(invoiceRef: $invoiceRef)
  }
`);

export const POST_INVOICE_MARK_AS_PAID = gql(`
  mutation ($invoiceRef: ID!, $paid: InvoicePaidInput) {
    invoiceMarkAsPaid(invoiceRef: $invoiceRef, paid: $paid) {
      ${INVOICE}
    }
  }
`);

export const GET_LISTS = gql(`
  query {
    lists {
      lists {
        ${LIST_FIELDS}
      }
    }
  }
`);

export const POST_LIST_ADD = gql(`
  mutation($listRef: ID!, $contactRef: ID!) {
    listAddContact(listRef: $listRef, contactRef: $contactRef) {
      ${LIST_FIELDS}
    }
  }
`);

export const POST_LIST_REMOVE = gql(`
  mutation($listRef: ID!, $contactRef: ID!) {
    listRemoveContact(listRef: $listRef, contactRef: $contactRef) {
      ${LIST_FIELDS}
    }
  }
`);
export const POST_LIST_CREATE = gql(`
  mutation($title: String!) {
    listAdd(title: $title) {
      ${LIST_FIELDS}
    }
  }
`);

export const POST_LIST_DELETE = gql(`
  mutation($listRef: ID!) {
    listDelete(listRef: $listRef)
  }
`);
