import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

export default function Loader(props) {
  return (
    <div className="loader" style={props.withMargins ? { marginTop: "5%" } : {}}>
      <BeatLoader size={props.size || 5} color={"black"} loading={true} />
    </div>
  );
}
