import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Loader from "../components/global/loader";
import { GET_USERS, POST_DELETE_USER, POST_USER } from "../graphql/queries";
import Button from "../components/styled/button";
import { Buffer } from "buffer";
import { Store } from "../stores/stores";

export default function Stylesheets() {
  const { data, loading, refetch } = useQuery(GET_USERS);
  const [addUser] = useMutation(POST_USER);
  const [deleteUser] = useMutation(POST_DELETE_USER);
  const [password, setPassword] = useState();
  const { addNotification } = Store.useState(s => s);

  const handleAddUser = form => {
    form.preventDefault();

    addUser({
      variables: {
        firstName: form.target.firstName.value,
        lastName: form.target.lastName.value,
        email: Buffer.from(form.target.email.value).toString("base64")
      }
    })
      .then(({ data }) => {
        addNotification({ ok: 1, message: "Admin added" });
        refetch();
        setPassword(data.usersAdd.password);
      })
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const handleDeleteUser = async ref => {
    deleteUser({ variables: { userRef: ref } }).then(e => refetch());
  };

  if (loading || !data) return <Loader />;

  return (
    <div id="stylesheets">
      <section className="header">
        <h1>Super Admins</h1>
      </section>
      {!password ? (
        <form onSubmit={handleAddUser} style={{ marginBottom: "20px" }}>
          <input name="email" type="email" required placeholder="Email..." />
          <input name="firstName" type="text" required placeholder="First name..." />
          <input name="lastName" type="text" required placeholder="Last name..." />
          <Button type="submit">Create Admin</Button>
        </form>
      ) : (
        <h1>Password to provide: {password}</h1>
      )}
      <div className="content">
        {data.users.users.map(d => (
          <div key={d.firstName} className="stylesheet">
            <h2>
              {d.firstName} {d.lastName}
            </h2>
            <p>{d.email}</p>
            <Button
              variant="danger"
              type="button"
              onClick={e => window.confirm("Are you sure?") && handleDeleteUser(d._id)}>
              Delete
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
