import React from "react";
import styled from "styled-components";

import colors from "@common-ground-io/common-assets/assets/colors.json";

const layout = {
  light: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyLighter,
      iconColor: "transparent",
      iconColorActive: colors.grey
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyLight,
      iconColor: "transparent",
      iconColorActive: colors.greyLightest
    }
  },
  dark: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDark,
      iconColor: "transparent",
      iconColorActive: colors.greyLightest
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDark,
      iconColor: "transparent",
      iconColorActive: colors.greyLightest
    }
  }
};

const Styledlabel = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(layout, props).fontColorLabel};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => path(layout, props).bcgColorInput};
  border-radius: 3px;
  transition: all 150ms;
`;

const Container = styled.div``;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${props => (props.checked ? path(layout, props).iconColorActive : path(layout, props).iconColor)};
  stroke-width: 2px;
`;

const computeVariant = (variant, layout) => (layout.light[variant] ? variant : "primary");
const path = (layout, props) => layout[props.theme.name || "light"][props.variant];

const Checkbox = props => {
  const variant = computeVariant(props.variant, layout);
  const { checked, className, disabled } = props;

  return (
    <Container className={`cg-common cg-checkbox ${variant}`}>
      <Styledlabel variant={variant}>
        <CheckboxContainer className={className}>
          <HiddenCheckbox disabled={disabled || false} checked={checked} {...props} label={undefined} />
          <StyledCheckbox checked={checked} {...props} variant={variant} name={undefined}>
            <Icon viewBox="0 0 24 24" variant={variant} checked={checked} name={undefined}>
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
        </CheckboxContainer>
        {props.label}
      </Styledlabel>
    </Container>
  );
};

export default Checkbox;
