import { useMutation } from "@apollo/client";
import InputWithSubmit from "../components/styled/inputWithSubmit";
import React, { useState } from "react";
import { POST_CREATE_PASSWORD_TOKEN } from "../graphql/queries";

export default function PasswordReset() {
  const [resetPassword] = useMutation(POST_CREATE_PASSWORD_TOKEN);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [result, setResult] = useState();

  const onSubmit = async e => {
    e.preventDefault();
    setError(null);
    try {
      const { data } = await resetPassword({ variables: { email } });
      console.log(data);
      setResult(data.createPasswordToken.message);
    } catch (error) {
      setError(error.toString());
    }
  };

  const handleChange = e => {
    const value = e.target.value;
    setEmail(value);
  };

  return (
    <div id="mfaQrCodeContainer">
      <form onSubmit={onSubmit} id="addMfaForm">
        <InputWithSubmit
          label="Enter super admin email address to reset"
          type="text"
          autoFocus
          autoComplete="off"
          name="email"
          placeholder="Enter email address"
          onChange={handleChange}
          value={email}
          required
        />
      </form>
      {error ? <p className="danger">{error}</p> : null}
      {result ? (
        <h2 className="">
          <a target="_blank" href={result} rel="noreferrer">
            {result}
          </a>
        </h2>
      ) : null}
    </div>
  );
}
