import React from "react";
import styled from "styled-components";

export function Logo() {
  return <img alt="" src="https://media.graphassets.com/oWKtMzA1R3OFx3IbF7PJ" />;
}

const StyledLogoImg = styled.img`
  content: ${({ theme }) => `url(${logo})`};
  width: 100px;
`;

export const NoTextComponent = () => <StyledLogoImg className={"cg-common cg-logo"} />;

const logo =
  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTZweCIgaGVpZ2h0PSI1NXB4IiB2aWV3Qm94PSIwIDAgOTYgNTUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYxLjIgKDg5NjUzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5Db21iaW5lZCBTaGFwZTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik02Ni45NTEsLTcuMTA1NDI3MzZlLTE1IEM3NS4zNDcsMTAuMDQ2IDg0Ljg5MSwxOS4xNTkgOTUuMzY3LDI3LjA1MyBDODQuODkxLDM1LjA5IDc1LjM0Nyw0NC4wNiA2Ni45NTEsNTQuMTA2IEM1OS45MTksNDUuNjM4IDUyLjAyNSwzNy44ODggNDMuNDE0LDMwLjg1NiBDNDMuNDg2LDM2LjY2OSA0My45MTYsNDIuNDgxIDQ0LjYzNCw0OC4yOTMgQzI5Ljc4LDQ2LjcxNSAxNC44NTQsNDYuNzE1IDEuNDIxMDg1NDdlLTE0LDQ4LjI5MyBDMS43MjIsMzQuMjI5IDEuNzIyLDIwLjAyIDEuNDIxMDg1NDdlLTE0LDUuODg0IEMxNC44NTQsNy41MzQgMjkuNzgsNy41MzQgNDQuNjM0LDUuODg0IEM0My45MTYsMTEuNjk2IDQzLjQ4NiwxNy41MDkgNDMuNDE0LDIzLjMyMSBDNTIuMDI1LDE2LjM2MSA1OS45MTksOC40NjcgNjYuOTUxLC03LjEwNTQyNzM2ZS0xNSBaIE0yMi4zMTcsMTkuOTQ5IEMxOC4yOTgsMTkuOTQ5IDE1LjE0MSwyMy4xMDYgMTUuMTQxLDI3LjA1MyBDMTUuMTQxLDMxIDE4LjI5OCwzNC4zIDIyLjMxNywzNC4zIEMyNi4yNjQsMzQuMyAyOS40OTMsMzEgMjkuNDkzLDI3LjA1MyBDMjkuNDkzLDIzLjEwNiAyNi4yNjQsMTkuOTQ5IDIyLjMxNywxOS45NDkgWiBNNjEuOTI4LDIxLjk1OCBDNTkuMTI5LDI0Ljc1NyA1OS4xMjksMjkuMzQ5IDYxLjkyOCwzMi4yMTkgQzY0LjcyNiwzNC45NDYgNjkuMjQ3LDM0Ljk0NiA3Mi4xMTgsMzIuMTQ4IEM3NC45MTYsMjkuMzQ5IDc0LjkxNiwyNC43NTcgNzIuMTE4LDIyLjAzIEM2OS4yNDcsMTkuMTU5IDY0LjcyNiwxOS4xNTkgNjEuOTI4LDIxLjk1OCBaIiBpZD0iQ29tYmluZWQtU2hhcGUiIGZpbGw9IiMwMDAwMDAiPjwvcGF0aD4KICAgIDwvZz4KPC9zdmc+";

export function Close(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
      <g fill="none" fillRule="evenodd">
        <g stroke="#FFF">
          <g>
            <g>
              <path
                d="M0 0l10 10M0 10L10 0"
                transform="translate(-1237 -499) translate(931 486) translate(306.709 13.478)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function Reduce(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="2" viewBox="0 0 11 2">
      <g fill="none" fillRule="evenodd" strokeLinecap="square">
        <g stroke="#FFF">
          <g>
            <g>
              <path d="M0 0.5L10 0.5" transform="translate(-1215 -509) translate(931 486) translate(284.987 23.413)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
