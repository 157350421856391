import React from "react";
import App from "./app";
import { ApolloClient, ApolloProvider, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Store } from "./stores/stores";
import { NotificationManager } from "react-notifications";
import { createUploadLink } from "apollo-upload-client";
import { createRoot } from "react-dom/client";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions.code === "UNAUTHENTICATED") {
        localStorage.removeItem("jwt");
        Store.update(s => {
          s.session = null;
        });
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createUploadLink({ uri: process.env.REACT_APP_API_ENDPOINT });
const authLink = setContext((_, { headers = {} }) => {
  const jwt = localStorage.getItem("jwt");
  const apiKey = process.env.REACT_APP_API_GLOBAL_KEY;
  if (jwt) headers["commonground-jwt"] = jwt;
  if (apiKey) headers["x-api-key"] = apiKey;
  return { headers };
});

const cache = new InMemoryCache({
  typePolicies: {
    ItemLabel: { keyFields: ["catno", "id"] },
    ItemArtist: { keyFields: ["id", "name"] },
    Track: { keyFields: ["title", "position", "uri"] },
    ConfigModule: { keyFields: ["id", "data"] }
  }
});

const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: cache
});

Store.update(s => {
  s.addNotification = data => {
    if (!data.ok) NotificationManager.error(data.message);
    else NotificationManager.info(data.message);
  };
  s.resetStore = async () => {
    await apolloClient.queryManager.fetchQueryRejectFns;
    await apolloClient.cache.reset();
  };
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ApolloProvider client={apolloClient}>
    <App apolloClient={apolloClient} />
  </ApolloProvider>
);
