import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Logo } from "../global/icons";
import useWindowSize from "../../hooks/useWindowSize";

export default function Menu(props) {
  const { isMobile } = useWindowSize(1080);
  const location = useLocation();

  return (
    <div id="menuContainer" className={`${isMobile ? "mobile" : ""}`}>
      <nav id="menu">
        <div className="logoAndButton">
          <Link className="logoLink" to="/">
            <Logo />
          </Link>
        </div>
        <div className="entries">
          <ul className="show">
            <li className={location.pathname === "/" || location.pathname === "/dashboard" ? "active" : ""}>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className={location.pathname === "/invoices" ? "active" : ""}>
              <Link to="/invoices">Invoices</Link>
            </li>
            <li className={location.pathname === "/configs" ? "active" : ""}>
              <Link to="/configs">Configs</Link>
            </li>
            <li className={location.pathname === "/contacts" ? "active" : ""}>
              <Link to="/contacts">Contacts</Link>
            </li>
            <li className={location.pathname === "/theme-browser-entry" ? "active" : ""}>
              <Link to="/theme-browser-entry">Theme browser</Link>
            </li>
            <li className={location.pathname === "/fonts" ? "active" : ""}>
              <Link to="/fonts">Fonts & Icons</Link>
            </li>
            <li className={location.pathname.includes("template") ? "active" : ""}>
              <Link to="/templates">Templates</Link>
            </li>
            <li className={location.pathname.includes("campaign") ? "active" : ""}>
              <Link to="/campaigns">Campaigns</Link>
            </li>
            <li className={location.pathname.includes("dictionar") ? "active" : ""}>
              <Link to="/dictionaries">Dictionaries</Link>
            </li>
            <li className={location.pathname.includes("password/reset") ? "active" : ""}>
              <Link to="/password/reset">Password reset</Link>
            </li>
            <li className={location.pathname.includes("admins") ? "active" : ""}>
              <Link to="/superadmins">Super Admins</Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
