import Modal from "react-modal";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Menu from "./components/global/menu";
import Navbar from "./components/global/navbar";
import Four0Four from "./components/global/404";
import Login from "./pages/login";
import "./styles/scss/app.scss";
import { NotificationContainer } from "react-notifications";
import { Store } from "./stores/stores";
import Invoices from "./pages/invoices";
import Configs from "./pages/configs";
import Dashboard from "./pages/dashboard";
import useWindowSize from "./hooks/useWindowSize";
import Intro from "./components/intro";
import { GET_SESSION } from "./graphql/queries";
import MfaQrCode from "./pages/mfaQrCode";
import MfaLogin from "./pages/mfaLogin";
import Fonts from "./pages/fonts";
import { useLazyQuery } from "@apollo/client";
import Dictionaries from "./pages/dictionaries";
import Dictionary from "./pages/dictionary";
import Templates from "./pages/templates";
import Template from "./pages/template";
import PasswordReset from "./pages/passwordReset";
import SuperAdmins from "./pages/superAdmins";
import Campaigns from "./pages/campaigns";
import Campaign from "./pages/campaign";
import ThemeBrowserEntries from "./pages/themeBrowserEntries";
import { ThemeProvider } from "styled-components";
import Contacts from "./pages/contacts";

function App() {
  const { session } = Store.useState(s => s);
  const [getSession, { loading: sessionIsLoading, data: sessionData }] = useLazyQuery(GET_SESSION);
  const { isMobile } = useWindowSize(992);
  const jwt = localStorage.getItem("jwt");

  useEffect(() => {
    if (jwt) getSession();
  }, [jwt, getSession]);

  Modal.setAppElement("#root");

  useEffect(() => {
    if (!sessionIsLoading && sessionData?.session) {
      localStorage.setItem("jwt", sessionData.session.jwt);
      Store.update(s => {
        s.session = sessionData.session;
      });
    }
  }, [sessionIsLoading, sessionData]);

  if (!jwt || session === null) return <Login />;
  else if (session === undefined) return <Intro />;
  else if (session && !session.user.mfa?.active) return <MfaQrCode />;
  else if (session && !session.mfa) return <MfaLogin />;

  return (
    <ThemeProvider theme={{ name: "light" }}>
      <div id="app" className={`${isMobile ? "mobile" : ""}`}>
        <NotificationContainer />
        <Router>
          {!isMobile ? <Menu /> : null}
          <div id="mainContent">
            <Navbar />
            <main id="content">
              <Switch>
                <Route exact path={["/dashboard", "/"]} component={Dashboard} />
                <Route exact path="/configs" component={Configs} />
                <Route exact path="/theme-browser-entry" component={ThemeBrowserEntries} />
                <Route exact path="/invoices" component={Invoices} />
                <Route exact path="/contacts" component={Contacts} />
                <Route exact path="/fonts" component={Fonts} />
                <Route exact path="/dictionaries" component={Dictionaries} />
                <Route exact path="/templates" component={Templates} />
                <Route exact path="/template/:ref" component={Template} />
                <Route exact path="/dictionary/:ref" component={Dictionary} />
                <Route exact path="/password/reset" component={PasswordReset} />
                <Route exact path="/superadmins" component={SuperAdmins} />
                <Route exact path="/campaigns" component={Campaigns} />
                <Route exact path="/campaign/:ref" component={Campaign} />
                <Route path="*" component={Four0Four} />
              </Switch>
            </main>
          </div>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
