import { gql } from "@apollo/client";

const CAMPAIGN_FIELDS = `
  _id
  id
  title
  status
  created
  uri
  html
  publication {
    status
    date
    isCGNews
  }
  fields {
    type
    name
    value
    description
    required
  }
  entries {
    _id
    listing {
      _id
      id
    }
    item {
      _id
      descriptions {
        main
      }
      data {
        title
      }
    }
  }
  list {
    _id
    title
    count
  }
  template {
    _id
    title
  }
`;

export const GET_CAMPAIGNS = gql(`
  query($page: Int) {
    campaigns(page: $page) {
      campaigns {
        ${CAMPAIGN_FIELDS}
      }
      pagination {
        pages
        page
      }
    }
  }
`);

export const GET_CAMPAIGN = gql(`
  query($campaignRef: ID!) {
    campaign(campaignRef: $campaignRef) {
      campaign {
        ${CAMPAIGN_FIELDS}
      }
      metrics {
        sendAt
        deliveryRate
        openRate
        bounceRate
      }
    }
  }
`);

export const POST_CAMPAIGN_CREATE = gql(`
  mutation($title: String!) {
    campaign : campaignCreate(title: $title) {
      ${CAMPAIGN_FIELDS}
    }
  }
`);

export const POST_CAMPAIGN_UPDATE = gql(`
  mutation($campaignRef: ID!, $campaignUpdateInput: CampaignUpdateInput!) {
    campaign: campaignUpdate(campaignRef: $campaignRef, campaignUpdateInput: $campaignUpdateInput) {
      ${CAMPAIGN_FIELDS}
    }
  }
`);

export const POST_CAMPAIGN_DELETE = gql(`
  mutation($campaignRef: ID!) {
    campaignDelete(campaignRef: $campaignRef)
  }
`);

export const POST_CAMPAIGN_TEST_EMAIL = gql(`
  mutation($campaignRef: ID!, $emails: [String!]!) {
    campaignTestEmail(campaignRef: $campaignRef, emails: $emails)
  }
`);

export const POST_CAMPAIGN_SEND = gql(`
  mutation($campaignRef: ID!, $sendAt: Date) {
    campaignSend(campaignRef: $campaignRef, sendAt: $sendAt) {
      ${CAMPAIGN_FIELDS}
    }
  }
`);

export const POST_CAMPAIGN_SET_IS_NEWS = gql(`
  mutation($campaignRef: ID!, $isNews: Boolean!) {
    campaignSetIsNews(campaignRef: $campaignRef, isNews: $isNews) {
      ${CAMPAIGN_FIELDS}
    }
  }
`);
