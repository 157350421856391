import React, { useEffect, useState } from "react";
import { Input, Zone, Select } from "@common-ground-io/common-assets/react";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  POST_FONT_UPLOAD,
  GET_FONTS,
  POST_FONT_CREATE,
  POST_FONT_UPDATE,
  POST_FONT_ADD_VARIATION,
  POST_FONT_UPDATE_VARIATION,
  POST_FONT_UPLOAD_VARIATION_FILES,
  POST_FONT_DELETE_VARIATION
} from "../graphql/queries";
import { Store } from "../stores/stores";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
import Button from "../components/styled/button";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "50%",
    transform: "translate(-50%, -50%)"
  }
};

export default function FontUploader() {
  const { addNotification } = Store.useState(s => s);
  const [fontUpload] = useMutation(POST_FONT_UPLOAD);
  const [fontCreate] = useMutation(POST_FONT_CREATE);
  const [getFonts, { data }] = useLazyQuery(GET_FONTS, { fetchPolicy: "cache-and-network" });
  const [creatingNewFont, setCreatingNewFont] = useState(false);

  useEffect(() => {
    getFonts();
  }, [getFonts]);

  const onSubmit = async (e, id) => {
    e.preventDefault();
    try {
      if (!e.target.font) throw new Error("Invalid file");
      const { data } = await fontUpload({ variables: { id, file: e.target.font.files[0] } });
      addNotification({ ok: 1, message: data.fontUpload });
    } catch (e) {
      addNotification({ ok: 1, message: e.data || e.toString() });
    }
  };

  function openModal() {
    setCreatingNewFont(true);
  }

  function closeModal() {
    setCreatingNewFont(false);
  }

  const handleNewFont = async e => {
    e.preventDefault();
    await fontCreate({ variables: { title: e.target.title.value } });
    getFonts();
    closeModal();
    addNotification({ ok: 1, message: "Font created" });
  };

  return (
    <div id="fonts">
      <Helmet>
        {data?.fonts?.edges ? <style>{data.fonts.edges.reduce((t, f) => t + f.fontFace, "")}</style> : null}
      </Helmet>
      <Modal isOpen={creatingNewFont} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <form onSubmit={handleNewFont} className="createFontModal">
          <Input name="title" placeholder="Font title..." required label="Font title" />
          <Button type="submit">Submit</Button>
        </form>
      </Modal>
      <div className="fonts">
        <div className="header">
          <h2>Eshop fonts</h2>
          <Button onClick={e => openModal()}>Create new font</Button>
        </div>
        <div className="entries">
          {data?.fonts?.edges?.map(f => (
            <Font addNotification={addNotification} key={f._id} font={f} />
          ))}
        </div>
      </div>
      <div className="iconFonts">
        <form onSubmit={e => onSubmit(e, "cg-font-backoffice-regular")}>
          <div>
            <h2>Backoffice icons </h2>
            <input type="file" required name="font" accept=".woff2"></input>
          </div>
          <button type="submit">Upload font</button>
        </form>
        <form onSubmit={e => onSubmit(e, "cg-font-eshop-regular")}>
          <div>
            <h2>Eshop icons (deprecated)</h2>
            <input type="file" required name="font" accept=".woff2"></input>
          </div>
          <button type="submit">Upload font</button>
        </form>
      </div>
    </div>
  );
}

const Font = ({ font, addNotification }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [type, setType] = useState(font.type);
  const [fontUpdate] = useMutation(POST_FONT_UPDATE);

  function openModal() {
    setIsEditing(true);
  }

  function closeModal() {
    setIsEditing(false);
  }

  const handleNewFont = async e => {
    e.preventDefault();
    await fontUpdate({
      variables: {
        ref: font._id,
        title: e.target.title.value,
        family: e.target.family.value,
        creator: e.target.creator.value,
        tags: e.target.tags.value.split(" "),
        type
      }
    });
    closeModal();
    addNotification({ ok: 1, message: "Font updated" });
  };

  const options = [
    { label: "Sans Serif", value: "sans-serif" },
    { label: "Serif", value: "serif" },
    { label: "Cursive", value: "cursive" },
    { label: "Display", value: "display" },
    { label: "Monospace", value: "monospace" },
    { label: "Icon", value: "icon" }
  ];

  return (
    <Zone className="font">
      <Modal isOpen={isEditing} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <form onSubmit={handleNewFont} className="createFontModal">
          <Input name="title" defaultValue={font.title} placeholder="Font title..." required label="Font title" />
          <Input name="family" defaultValue={font.family} placeholder="Font family..." required label="Font family" />
          <Input name="creator" defaultValue={font.creator} placeholder="Creator..." required label="Creator" />
          <Select
            label="Font type"
            value={options.find(o => o.value === type)}
            options={options}
            onChange={e => setType(e.value)}
          />
          <Input
            autoComplete="none"
            name="tags"
            defaultValue={font.tags.join(" ")}
            placeholder="Tags..."
            required
            label="Tags"
          />
          <Button type="submit">Submit</Button>
        </form>
      </Modal>
      <div className="header">
        <p style={{ fontFamily: font.family, fontSize: "20px", fontWeight: "normal", fontStyle: "normal" }}>
          {font.title} - {font.type} by {font.creator} - {font.tags.join(", ")}
        </p>
        <div>
          <Button variant="secondary" onClick={e => openModal()}>
            Edit font info
          </Button>
          <Button variant="secondary" onClick={e => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? "Open" : "Close"}
          </Button>
        </div>
      </div>
      {!isCollapsed ? (
        <>
          <div className="variations">
            {font.variations.map(v => (
              <Variation key={v._id} addNotification={addNotification} variation={v} font={font} />
            ))}
          </div>
          <AddVariation addNotification={addNotification} font={font} />
        </>
      ) : null}
    </Zone>
  );
};

const AddVariation = ({ font, addNotification }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [addVariation] = useMutation(POST_FONT_ADD_VARIATION);

  const handleAddVariation = async e => {
    e.preventDefault();
    await addVariation({ variables: { ref: font._id, title: e.target.title.value } });
    setIsEditing(false);
    addNotification({ ok: 1, message: "Variation created" });
  };

  return (
    <div className="addVariation">
      <Modal
        isOpen={isEditing}
        onRequestClose={() => setIsEditing(false)}
        style={customStyles}
        contentLabel="Example Modal">
        <form onSubmit={handleAddVariation} className="createFontModal">
          <Input name="title" defaultValue="" placeholder="Variation title..." required label="Variation title" />
          <Button type="submit">Submit</Button>
        </form>
      </Modal>
      <Button onClick={e => setIsEditing(true)} type="button">
        Add variation
      </Button>
    </div>
  );
};

const Variation = ({ variation, font, addNotification }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [style, setStyle] = useState(variation.style);
  const [weight, setWeight] = useState(variation.weight);
  const [fontVariationUpdate] = useMutation(POST_FONT_UPDATE_VARIATION);
  const [fontVariationUploadFile, { loading: uploadingFiles }] = useMutation(POST_FONT_UPLOAD_VARIATION_FILES);
  const [fontVariationDelete] = useMutation(POST_FONT_DELETE_VARIATION);

  const handleNewFont = async e => {
    e.preventDefault();
    await fontVariationUpdate({
      variables: {
        variationRef: variation._id,
        ref: font._id,
        title: e.target.title.value,
        weight,
        style
      }
    });
    setIsEditing(false);
    addNotification({ ok: 1, message: "Font created" });
  };

  const handleUploadFont = async e => {
    e.preventDefault();

    await fontVariationUploadFile({
      variables: {
        variationRef: variation._id,
        ref: font._id,
        files: e.target.file.files
      }
    });
    setIsUploading(false);
    addNotification({ ok: 1, message: "File uploaded" });
  };

  const handleDeleteVariation = async e => {
    await fontVariationDelete({
      variables: {
        variationRef: variation._id,
        ref: font._id
      }
    });
    addNotification({ ok: 1, message: "Variation deleted" });
  };

  const styleOptions = [
    { label: "normal", value: "normal" },
    { label: "italic", value: "italic" }
  ];

  const weightOptions = [
    { label: "100 (extra-light)", value: "100" },
    { label: "200 (light)", value: "200" },
    { label: "300 (semi-light)", value: "300" },
    { label: "400 (normal)", value: "400" },
    { label: "500 (medium)", value: "500" },
    { label: "600 (semi-bold)", value: "600" },
    { label: "700 (bold)", value: "700" },
    { label: "800 (black)", value: "800" },
    { label: "900 (extra-black)", value: "900" }
  ];

  const icons = [];
  for (let i = 59904; i < 59904 + 30; i++) {
    icons.push(i);
  }
  return (
    <div className="variation">
      <Modal isOpen={isEditing} onRequestClose={() => setIsEditing(false)} style={customStyles}>
        <form onSubmit={handleNewFont} className="createFontModal">
          <Input
            name="title"
            defaultValue={variation.title}
            placeholder="Variation title..."
            required
            label="Variation title"
          />
          <Select
            label="Font weight"
            value={weightOptions.find(o => o.value === weight)}
            options={weightOptions}
            onChange={e => setWeight(e.value)}
          />
          <Select
            label="Font style"
            value={styleOptions.find(o => o.value === style)}
            options={styleOptions}
            onChange={e => setStyle(e.value)}
          />
          <p>
            {variation.files.map(f => (
              <span>
                {f.fileName} <br />
              </span>
            ))}
          </p>
          <Button type="submit" disabled={!!uploadingFiles}>
            {uploadingFiles ? "Loading..." : "Submit"}
          </Button>
        </form>
      </Modal>
      <Modal isOpen={isUploading} onRequestClose={() => setIsUploading(false)} style={customStyles}>
        <form onSubmit={handleUploadFont} className="createFontModal">
          <input type="file" multiple name="file" accept=".ttf,.otf,.woff,.woff2,.eot" required />
          <Button type="submit" disabled={!!uploadingFiles}>
            {uploadingFiles ? "Loading..." : "Upload"}
          </Button>
        </form>
      </Modal>
      <div className="header">
        <p>
          {variation.title} - ({variation.files.map(f => f.format).join(", ")})
        </p>
        <div>
          <Button variant="secondary" onClick={e => setIsEditing(true)} type="button">
            Edit
          </Button>
          <Button variant="secondary" onClick={e => setIsUploading(true)} type="button">
            Upload files
          </Button>
          <Button
            variant="secondary"
            onClick={e => window.confirm("Are you sure?") && handleDeleteVariation()}
            type="button">
            Delete
          </Button>
        </div>
      </div>
      <div className="preview">
        <p
          style={{
            fontFamily: font.family,
            fontSize: "40px",
            fontWeight: variation.weight,
            fontStyle: variation.style
          }}>
          {font.type === "icon"
            ? icons.map(i => <span>{String.fromCodePoint(i)} </span>)
            : "€,$,£ ABCDabc 123 The Quick Brown Fox"}
        </p>
      </div>
    </div>
  );
};
