import React from "react";
import Loader from "./global/loader";
import { Select } from "../components/styled/select";
import { useQuery } from "@apollo/client";
import { GET_CONFIGS } from "../graphql/queries";
import clone from "clone";

const ConfigSelector = ({ onChangeConfig }) => {
  const { data } = useQuery(GET_CONFIGS, { variables: { status: "active" } });

  if (!data?.configsConnection) return <Loader />;
  const configList = clone(data.configsConnection.configs);

  const handleChange = e => {
    onChangeConfig(e.value);
  };

  const currentDomain = localStorage.getItem("origin");

  const sorter = (a, b) => (a.shopName.toLowerCase() > b.shopName.toLowerCase() ? 1 : -1);
  const activeConfigs = configList.sort(sorter);

  const options = [
    { label: "All configs", value: null },
    ...activeConfigs.map(c => ({
      label: c.modules.find(m => m.id === "information").data.shopName,
      value: c
    }))
  ];

  let defaultOption = options[0];

  if (currentDomain) {
    const selectedConfig = configList.find(c => c.sites.find(s => s.domain === currentDomain));
    if (selectedConfig)
      defaultOption = { label: selectedConfig.modules.find(m => m.id === "information").data.shopName };
  }

  return <Select defaultValue={defaultOption} options={options} onChange={handleChange} className="shopSelector" />;
};

export default ConfigSelector;
