import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { MainLogoLetters, NoTextComponent } from "../components/global/icons";
import { POST_MFA_AUTHENTICATE } from "../graphql/queries";
import { Store } from "../stores/stores";

export default function MfaLogin() {
  const { addNotification } = Store.useState(s => s);
  const [mfaAuthenticate] = useMutation(POST_MFA_AUTHENTICATE);
  const [error, setError] = useState(null);
  const [token, setToken] = useState("");

  const onSubmit = async token => {
    try {
      const results = await mfaAuthenticate({ variables: { token } });
      const succeeded = !!results?.data?.authenticateMfa;
      if (succeeded) {
        const user = results.data.authenticateMfa.user;
        addNotification({ ok: 1, message: `Hi ${user.name}` });
      } else setError("Authentication error");
      Store.update(s => {
        s.session = results?.data?.authenticateMfa;
      });
    } catch (e) {
      setToken("");
      setError(e.toString());
    }
  };

  const handleChange = e => {
    const value = e.target.value;
    setToken(value);
    if (value.length === 6) {
      onSubmit(value);
    }
  };

  return (
    <div id="mfaLoginWrapper">
      <div id="mfaLogin">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <NoTextComponent />
        </div>
        <form id="mdaLoginForm" onSubmit={onSubmit}>
          <div className="formGroup">
            <label>Enter token from your authenticator app:</label>
            <input
              type="text"
              autoFocus
              autoComplete="off"
              name="token"
              pattern="[0-9]{6}"
              placeholder="Enter 6 digits code"
              onChange={handleChange}
              value={token}
              required
            />
          </div>
        </form>
        {error ? <p className="danger">{error}</p> : null}
      </div>
    </div>
  );
}
