import React from "react";

export default function FourOFour (props) {
  return (
    <div id="fourOFour">
      <section>
        <h1>{props.message || "The page you requested wasn't found"}</h1>
        {props.children}
      </section>
    </div>
  );
}
